import { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Box, Button, TextField, Alert, Typography, Select, MenuItem, InputLabel } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { jwtDecode } from "jwt-decode";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getAll, upsert } from "../../apiService";

const initialValues = {
    eddEntityId: '',
    firstName: '',
    lastName: '',
    email: '',
}

const entitySchema = yup.object().shape({
    eddEntityId: yup.string().required("Dit veld is verplicht"),
    firstName: yup.string().required("Dit veld is verplicht"),
    lastName: yup.string().required("Dit veld is verplicht"),
    email: yup.string().email("Dit is geen emailadres").required("Dit veld is verplicht"),
});

const CreateDogHandler = () => {
    const navigate = useNavigate();
    const { token } = useContext(AuthContext);
    const user = jwtDecode(token);
    const [entities, setEntities] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const handleOnCreate = async (values) => {
        try {
            values.displayName = values.firstName + ' ' + values.lastName;

            await upsert('dog-handlers', token, values);

            let  navigateState = {};

            if (user.role === 'BTBB-Medewerker') {
                navigateState = {
                    state: { 
                        entityId: values.eddEntityId 
                    },
                };
            }

            navigate('/hondengeleider', navigateState);
        } catch (error) {
            setError(true);
            setErrorMessage('Er ging helaas wat fout tijdens het opslaan van de hondengeleider');
        } 
    };

    useEffect(() => {
        const getEntities = async () => {
            setEntities(await getAll('edd-entities', token));
        }

        if (!entities) {
            getEntities();
        }

        if (entities?.length === 1) {
            initialValues.eddEntityId = entities[0].id;
        }
    }, [token, entities]);

    if (!entities) {
        return null;
    }

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Hondengeleider aanmaken</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Formik onSubmit={handleOnCreate} initialValues={initialValues} validationSchema={entitySchema} enableReinitialize>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }}}>
                            <Box sx={{ display: entities?.length === 1 ? 'none' : 'initial' }} >
                                <InputLabel id="eddEntityId" sx={{ position: 'absolute' }}>Entiteit</InputLabel>
                                <Select
                                    labelId="eddEntityId"
                                    type="text" 
                                    onBlur={handleBlur} 
                                    onChange={handleChange} 
                                    value={values.eddEntityId} 
                                    name="eddEntityId" 
                                    error={!!touched.eddEntityId && !!errors.eddEntityId} 
                                    helperText={touched.eddEntityId && errors.eddEntityId}
                                    sx={{ width: '100%' }}
                                >
                                    {entities && entities.map(entity => (
                                        <MenuItem value={entity.id}>{entity.name}</MenuItem>
                                    ))} 
                                </Select>
                            </Box>
                            <TextField  
                                type="text" 
                                label="ID" 
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.id} 
                                name="id" 
                                error={!!touched.id && !!errors.id} 
                                helperText={touched.id && errors.id}
                                sx={{ display: 'none' }}
                            />
                            <TextField  
                                type="text" 
                                label="Voornaam" 
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.firstName} 
                                name="firstName" 
                                error={!!touched.firstName && !!errors.firstName} 
                                helperText={touched.firstName && errors.firstName}
                            />
                            <TextField  
                                type="text" 
                                label="Achternaam" 
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.lastName} 
                                name="lastName" 
                                error={!!touched.lastName && !!errors.lastName} 
                                helperText={touched.lastName && errors.lastName}
                            />
                            <TextField  
                                type="text" 
                                label="Emailadres" 
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.email} 
                                name="email" 
                                error={!!touched.email && !!errors.email} 
                                helperText={touched.email && errors.email}
                            />
                        </Box>
                        <Box display="flex" justifyContent="start" mt="20px">
                            <Button type="submit" color="primary" variant="contain">
                                Aanmaken
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
}

export default CreateDogHandler;