import { createTheme } from '@mui/material/styles'

export const tokens = () => ({
    ...({
            white: "#FAF9F6",
            font: "#1B1212",
            primary: "#F39900",
            secondary: "#133563",
            blueAccent: "#004E83",
            grey: {
                25: "#F5F7F8",
                50: "#ECEFF1",
                100: "#CFD8DC",
            },
        }
    )
});

// mui theme settings
export const themeSettings = () => {
    const colors = tokens();

    return {
        palette: {
            ...({
                primary: {
                    main: colors.primary,
                },
                secondary: {
                    main: colors.secondary,
                },
                neutral: {
                    dark: colors.grey[100],
                    main: colors.grey[50],
                    light: colors.grey[25],
                },
                background: {
                    default: colors.grey[50],
                }
            }
        )
        },
        typography: {
            fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
            fontSize: 12,
            h1: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 32,
                '@media (min-width:600px)': {
                    fontSize: 40,
                },
            },
            h2: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 24,
                '@media (min-width:600px)': {
                    fontSize: 32,
                },
            },
            h3: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 20,
                '@media (min-width:600px)': {
                    fontSize: 24,
                },
            },
            h4: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 16,
                '@media (min-width:600px)': {
                    fontSize: 20,
                },
            },
            h5: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 14,
                '@media (min-width:600px)': {
                    fontSize: 16,
                },
            },
            h6: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 12,
                '@media (min-width:600px)': {
                    fontSize: 14,
                },
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: ({ ownerState}) => ({
                        backgroundColor: colors.primary,
                        color: colors.white,
                        textTransform: 'uppercase', 
                        ...(ownerState.variant === 'outlined' && {
                            backgroundColor: colors.white,
                            color: colors.font,
                            borderColor: colors.font
                        }),
                    }),
                },
            }
        }
    };
};

export const useTheme = () => {
    const theme = createTheme(themeSettings());

    return theme;
}