import { useTheme, tokens } from '../theme';
import { Box, Avatar, Tooltip } from "@mui/material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import dayjs from "dayjs";

const PlanningCard = ({ periods, onView }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette);

    return (
        <Box padding="12px" backgroundColor={colors.white} border={1} borderRadius="10px" borderColor={colors.font}>
            <table className="periodCard">
                <thead>
                    <tr>
                        <th>Start datum</th>
                        <th>Eind datum</th>
                        <th>Acties</th>
                    </tr>
                </thead>
                <tbody>
                    {periods && periods.map((period, index) => (
                        <tr key={index}>
                            <td>{dayjs(period.startDate).format('DD-MM-YYYY')}</td>
                            <td>{dayjs(period.endDate).format('DD-MM-YYYY')}</td>
                            <td>
                                <Box display="flex">
                                    {onView && (
                                        <Tooltip title="Plannen">
                                            <Avatar onClick={() => onView(period.id)} sx={{ bgcolor: colors.primary, color: colors.font, cursor: "pointer" }}>
                                                <CalendarTodayIcon />
                                            </Avatar>
                                        </Tooltip>
                                    )}
                                </Box>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Box>
    );
};

export default PlanningCard;