import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Box, Button, TextField, Alert, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getById, upsert } from "../../apiService";

const entitySchema = yup.object().shape({
    name: yup.string().required("Dit veld is verplicht"),
    street: yup.string().required("Dit veld is verplicht"),
    houseNumber: yup.string().required("Dit veld is verplicht"),
    zipCode: yup.string().required("Dit veld is verplicht"),
    city: yup.string().required("Dit veld is verplicht"),
});

const UpdateEntity = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { token } = useContext(AuthContext);
    const [initialValues, setInitialValues] = useState(null);
    const [entity, setEntity] = useState(null);
    const [location, setLocation] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const handleOnUpdate = async (values) => {
        try {
            let locationObject = {
                id: values.locationId,
                street: values.street,
                houseNumber: values.houseNumber,
                zipCode: values.zipCode,
                city: values.city,
            };

            await upsert('locations', token, locationObject);

            const entityObject = {
                name: values.name,
                locationId: locationObject.id
            };

            await upsert('edd-entities', token, entityObject);
            navigate('/entiteit');
        } catch (error) {
            setError(true);
            setErrorMessage('Er ging helaas wat fout tijdens het opslaan van de entiteit');
        } 
    };

    useEffect(() => {
        const getEntity = async () => {
            setEntity(await getById('edd-entities', token, state.entityId));
        }

        const getLocation = async () => {
            setLocation(await getById('locations', token, entity.locationId));
        };

        const getInitialValues = () => {
            setInitialValues({
                id: entity.id,
                locationId: location.id,
                name: entity.name,
                street: location.street,
                houseNumber: location.houseNumber,
                zipCode: location.zipCode,
                city: location.city,
            });
        }

        if (!state?.entityId) {
            navigate('/entiteit');
        }

        if (!entity) {
            getEntity();
        }

        if (entity && !location) {
            getLocation();
        }

        if (entity && location && !initialValues) {
            getInitialValues();
        }
    }, [token, state, entity, location, initialValues, navigate]);

    if (!entity || !initialValues) {
        return null;
    }

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Entiteit bewerken</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Formik onSubmit={handleOnUpdate} initialValues={initialValues} validationSchema={entitySchema}>
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }}}>
                        <TextField  
                            type="text" 
                            label="ID" 
                            onBlur={handleBlur} 
                            onChange={handleChange} 
                            value={values.id} 
                            name="id" 
                            error={!!touched.id && !!errors.id} 
                            helperText={touched.id && errors.id}
                            sx={{ display: 'none' }}
                        />
                        <TextField  
                            type="text" 
                            label="LocationId" 
                            onBlur={handleBlur} 
                            onChange={handleChange} 
                            value={values.LocationId} 
                            name="LocationId" 
                            error={!!touched.LocationId && !!errors.LocationId} 
                            helperText={touched.LocationId && errors.LocationId}
                            sx={{ display: 'none' }}
                        />
                        <TextField  
                            type="text" 
                            label="Naam" 
                            onBlur={handleBlur} 
                            onChange={handleChange} 
                            value={values.name} 
                            name="name" 
                            error={!!touched.name && !!errors.name} 
                            helperText={touched.name && errors.name}
                        />
                        <TextField  
                            type="text" 
                            label="Straat" 
                            onBlur={handleBlur} 
                            onChange={handleChange} 
                            value={values.street} 
                            name="street" 
                            error={!!touched.street && !!errors.street} 
                            helperText={touched.street && errors.street}
                        />
                        <TextField  
                            type="text" 
                            label="Huisnummer" 
                            onBlur={handleBlur} 
                            onChange={handleChange} 
                            value={values.houseNumber}
                            name="houseNumber"
                            error={!!touched.houseNumber && !!errors.houseNumber} 
                            helperText={touched.houseNumber && errors.houseNumber}
                        />
                        <TextField  
                            type="text" 
                            label="Postcode" 
                            onBlur={handleBlur} 
                            onChange={handleChange} 
                            value={values.zipCode} 
                            name="zipCode" 
                            error={!!touched.zipCode && !!errors.zipCode} 
                            helperText={touched.zipCode && errors.zipCode}
                        />
                        <TextField  
                            type="text" 
                            label="Stad" 
                            onBlur={handleBlur} 
                            onChange={handleChange} 
                            value={values.city} 
                            name="city" 
                            error={!!touched.city && !!errors.city} 
                            helperText={touched.city && errors.city}
                        />
                    </Box>
                    <Box display="flex" justifyContent="start" mt="20px">
                        <Button type="submit" color="primary" variant="contain">
                            Wijzigen
                        </Button>
                    </Box>
                </form>
            )}
            </Formik>
        </Box>
    );
}

export default UpdateEntity;