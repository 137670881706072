import React, { useContext } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Box, Typography, useTheme, Button } from '@mui/material';
import { tokens } from '../../theme';

const LoginPage = () => {
    const { login } = useContext(AuthContext);
    const theme = useTheme();
    const colors = tokens(theme.palette);

    const handleLogin = () => {
        login();
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            <img className="luchtmacht-logo" alt="Logo BTBB" src={`../../assets/Marechaussee logo.png`} />
            <Box className="login-box" display="flex" flexDirection="column" alignItems="center" backgroundColor={colors.white}>
                <Typography className="text-center" variant="h1" color={colors.font} sx={{ pb: "32px" }}>Certificeringsplanner</Typography>
                <img className="btbb-logo" alt="Logo BTBB" src={`../../assets/BTBB logo.png`} />
                <Typography className="text-center" variant="h4" color={colors.font} sx={{ pb: "32px" }}>Login in door middel van de onderstaande knop</Typography>
                <Button onClick={handleLogin} color="primary" variant="contain">Inloggen</Button> 
            </Box>
        </Box>
    );
};

export default LoginPage;