import { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Box, Button, TextField, Alert, Typography } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getAll, upsert } from "../../apiService";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

const entitySchema = yup.object().shape({
    startDate: yup.string().required("Dit veld is verplicht"),
    endDate: yup.string().required("Dit veld is verplicht"),
});

const CreatePlanPeriod = () => {
    const navigate = useNavigate();
    const { token } = useContext(AuthContext);
    const [initialValues, setInitialValues] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const checkStartDateBeforeEndDate = (values) => {
        const startDate = dayjs(values.startDate);
        const endDate = dayjs(values.endDate);
        const currentDate = dayjs();

        if (startDate.isBefore(currentDate, 'day') && endDate.isBefore(currentDate, 'day') && startDate.isBefore(endDate, 'day')) {
            return true;
        }
        return false;
    }

    const checkOverlap = async (newPlanPeriod) => {
        const newStartDate = newPlanPeriod.startDate;
        const newEndDate = newPlanPeriod.endDate;
        const planPeriods = await getAll('plan-periods', token);

        for (let i = 0; i < planPeriods.length; i++) {
            const startDate = dayjs(planPeriods[i].startDate);
            const endDate = dayjs(planPeriods[i].endDate);

            if (
                (newStartDate.isBefore(startDate) && newStartDate.isAfter(endDate))
                || (newEndDate.isBefore(startDate) && newEndDate.isAfter(endDate))
                || (newStartDate.isBefore(startDate) && newEndDate.isAfter(endDate))
            ) {
                return true;
            }

            return false;
        }
    }

    const handleOnCreate = async (values) => {
        try {
            const planPeriod = {
                startDate: dayjs(values.startDate),
                endDate: dayjs(values.endDate),
            }

            if (checkStartDateBeforeEndDate(planPeriod)) {
                setError(true);
                setErrorMessage('De begin datum moet voor de eind datum liggen');
                return null;
            }

            if (await checkOverlap(planPeriod)) {
                setError(true);
                setErrorMessage('Deze periode heeft overlap met andere periodes, pas de datum aan');
                return null;
            }

            await upsert('plan-periods', token, planPeriod);
            navigate('/periode');
        } catch (error) {
            setError(true);
            setErrorMessage('Er ging helaas wat fout tijdens het opslaan van de planperiode');
        } 
    };

    useEffect(() => {
        const getPlanPeriods = async () => {
            const planPeriods = await getAll('plan-periods', token);
            console.log(planPeriods)
            const mostRecentPlanPeriod = planPeriods.reduce((prev, current) => {
                return (dayjs(current.startDate) > dayjs(prev.startDate) ? current : prev);
            });

            const startDate = mostRecentPlanPeriod ? dayjs(mostRecentPlanPeriod.endDate).add(1, 'day') : null;
            setInitialValues({
                startDate: startDate ? startDate : '',
                endDate: startDate ? startDate.add(2, 'month') : '',
            })
        }

        if (!initialValues) {
            getPlanPeriods();
        }
    }, [token, initialValues]);

    if (!initialValues) {
        return null;
    }

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Planperiode aanmaken</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Formik onSubmit={handleOnCreate} initialValues={initialValues} validationSchema={entitySchema} enableReinitialize>
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Field name="startDate">
                                {({ field }) => (
                                    <DatePicker
                                        {...field}
                                        label="Begin datum"
                                        value={values.startDate}
                                        format="DD-MM-YYYY"
                                        onChange={(date) => handleChange({ target: { name: "startDate", value: date } })}
                                        onBlur={handleBlur}
                                        renderInput={(params) => <TextField {...params} helperText={touched.startDate && errors.startDate} error={touched.startDate && Boolean(errors.startDate)} />}
                                    />
                                )}
                            </Field>
                            <Field name="endDate">
                                {({ field }) => (
                                    <DatePicker
                                        {...field}
                                        label="Eind datum"
                                        value={values.endDate}
                                        format="DD-MM-YYYY"
                                        onChange={(date) => handleChange({ target: { name: "endDate", value: date } })}
                                        onBlur={handleBlur}
                                        renderInput={(params) => <TextField {...params} helperText={touched.endDate && errors.endDate} error={touched.endDate && Boolean(errors.endDate)} />}
                                    />
                                )}
                            </Field>
                        </LocalizationProvider>
                        
                    </Box>
                    <Box display="flex" justifyContent="start" mt="20px">
                        <Button type="submit" color="primary" variant="contain">
                            Aanmaken
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
        </Box>
    );
}

export default CreatePlanPeriod;