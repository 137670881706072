import { Box, Alert, Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useTitle } from "../../providers/TitleProvider";
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { getById, getAll, remove } from '../../apiService';
import PeriodCard from "../../components/PeriodCard";
import dayjs from "dayjs";

const PlanPeriods = () => {
    const navigate = useNavigate();
    const { token } = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fetched, setFetched] = useState(false); 
    const [activePlanPeriod, setActivePlanPeriod] = useState(null);
    const [futurePlanPeriods, setFuturePlanPeriods] = useState(null);
    const [pastPlanPeriods, setPastPlanPeriods] = useState(null);
    const [open, setOpen] = useState(false);
    const { updateTitle } = useTitle();
    const [planPeriodToRemove, setPlanPeriodToRemove] = useState(null);

    const handleOnRemove = async (id) => {
        if (open) {
            try {
                await remove('plan-periods', token, id);
                setFetched(false);
                setOpen(false);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het verwijderen van de planperiode');
            } 
        } else {
            setPlanPeriodToRemove(await getById('plan-periods', token, id));
            setOpen(true);
        }
    }
    
    const handleOnNotRemove = () => {
        setOpen(false);
        setPlanPeriodToRemove(null);
    };

    const handleOnView = async (id) => {
        navigate('/periode/overzicht', { state: { planPeriodId: id }});
    }

    const handleOnEdit = async (id) => {
        const period = await getById('plan-periods', token, id);

        if (period.planPeriodStatus) {
            navigate('/periode/starten', { state: { planPeriodId: id }});
        } else {
            navigate('/periode/wijzigen', { state: { planPeriodId: id }});
        }
    }

    const handleOnCreate = () => {
        navigate('/periode/aanmaken');
    }

    useEffect(() => {
        updateTitle('Planperioden');
    }, [updateTitle]);

    useEffect(() => {
        const getAllPlanPeriods = async () => {
            try {
                const currentDate = new Date();
                const planPeriods = await getAll('plan-periods', token);

                setActivePlanPeriod(planPeriods.find(period => {
                    const startDate = new Date(period.startDate);
                    const endDate = new Date(period.endDate);
                    return startDate <= currentDate && endDate >= currentDate;
                }));

                setPastPlanPeriods(planPeriods.filter(period => {
                    const endDate = new Date(period.endDate);
                    return endDate < currentDate;
                }));

                setFuturePlanPeriods(planPeriods.filter(period => {
                    const startDate = new Date(period.startDate);
                    return startDate > currentDate;
                }));

                setFetched(true);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de planperioden');
            } 
        }

        getAllPlanPeriods();
    }, [token, fetched]);

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Planperioden overzicht</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Box mb="20px">
                <Typography variant="h3" mb="20px">Actieve planperiode</Typography>
                {activePlanPeriod ? (
                    <PeriodCard periods={[activePlanPeriod]} onView={handleOnView} onEdit={handleOnEdit} />
                ) : (
                    <Typography>Er is momenteel geen planperiode actief</Typography>
                )}
            </Box>
            <Box mb="20px">
                <Typography variant="h3" mb="20px">Geplande planperiode</Typography>
                {futurePlanPeriods?.length ? (
                    <PeriodCard periods={futurePlanPeriods} onView={handleOnView} onRemove={handleOnRemove} onEdit={handleOnEdit} />
                ) : (
                    <Typography>Er zijn momenteel geen periodes gepland </Typography>
                )}
            </Box>
            <Box mb="20px">
                <Typography variant="h3" mb="20px">Afgeronde planperioden</Typography>
                {pastPlanPeriods?.length ? (
                    <PeriodCard periods={pastPlanPeriods} onView={handleOnView} onRemove={handleOnRemove} onEdit={handleOnEdit} />
                ) : (
                    <Typography>Er zijn momenteel geen afgeronde periodes</Typography>
                )}
            </Box>
            <Button onClick={handleOnCreate}>Nieuwe periode toevoegen</Button>
            <Dialog 
                open={open}
                onClose={handleOnNotRemove}
                aria-labelledby="alert-title"
                aria-describedby="alert-description"
            >
                <DialogTitle id="alert-title">
                    <Typography variant="h3">
                        Weet u dit zeker?
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-description">
                        <Typography variant="h5">
                            Weet u zeker dat de periode van {dayjs(planPeriodToRemove?.startDate).format('DD-MM-YYYY')} tot {dayjs(planPeriodToRemove?.endDate).format('DD-MM-YYYY')} met alle bijhorende tijdsloten en inschrijving wil verwijderen?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnNotRemove} variant="outlined">Annuleren</Button>
                    <Button onClick={() => handleOnRemove(planPeriodToRemove?.id)}>Verwijderen</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default PlanPeriods;