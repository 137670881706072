import { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Box, Typography, Grid, Alert } from "@mui/material";
import { getAll, getById } from '../../apiService';
import dayjs from "dayjs";
import Card from "../../components/Card";
import TimeSlotHandler from '../../components/TimeSlotHandler';

const DogHandlerDashboard = () => {
    const { token } = useContext(AuthContext);
    const [timeSlots, setTimeSlots] = useState(null);
    const [errorTimeSlots, setErrorTimeSlots] = useState(false);
    const [errorMessageTimeSlots, setErrorMessageTimeSlots] = useState(null);
    const [teams, setTeams] = useState(null);
    const [errorTeams, setErrorTeams] = useState(false);
    const [errorMessageTeams, setErrorMessageTeams] = useState(null);

    useEffect(() => {
        const getTimeSlots = async () => {
            try {
                const slots = await getAll('time-slots', token);
                
                slots.forEach(async slot => {
                    if (slot.certificationDate.locationId) {
                        slot.certificationDate.location = await getById('locations', token, slot.certificationDate.locationId);
                    }
                });

                setTimeSlots(slots);
            } catch (error) {
                setErrorTimeSlots(true);
                setErrorMessageTimeSlots('Er ging helaas wat fout tijdens het ophalen van de certificeringsdata');
            } 
        }

        const getTeams = async () => {
            try {
                const eddTeams = await getAll('edd-teams', token);
                const certifications = await getAll('certification-results', token);
        
                eddTeams.forEach(team => {
                    if (team.certificationResults === null) {
                        team.certificationResults = [];
                    }

                    certifications.forEach(certification => {
                        if (certification.eddTeamId === team.id) {
                            if (certification.expirationDate) {
                                team.expirationDate = certification.expirationDate;
                            }

                            team.certificationResults.push(certification);
                        }
                    });
                });

                setTeams(eddTeams);
            } catch (error) {
                setErrorTeams(true);
                setErrorMessageTeams('Er ging helaas wat fout tijdens het ophalen van de teams');
            } 
        }

        if (!timeSlots) {
            getTimeSlots();
        }

        if (!teams) {
            getTeams();
        }
    }, [token, timeSlots, teams]);

    return (
        <Box>
            <Typography variant="h2" mb="40px">Geplande certificeringsdata</Typography>
            <Grid container spacing={2}>
                {timeSlots && timeSlots.map(timeSlot => (
                    <Grid item xs={4}>
                        <TimeSlotHandler timeSlot={timeSlot}  />
                    </Grid>
                ))}
            </Grid>
            {errorTimeSlots && (
                <Alert severity="error" mb="40px">{errorMessageTimeSlots}</Alert>
            )}
            <Typography variant="h2" mb="40px" mt="60px">Team informatie</Typography>
            <Grid container spacing={2}>
                {teams && teams.map(team => (
                    <Grid item xs={4}>
                        <Card title={`${team.dogHandler.displayName} - ${team.dog.name}`}>
                            <Typography variant="h6">Chipnummer: {team.dog.chipNumber}</Typography>
                            <Typography variant="h6">Certificeringspogingen: {team.certificationResults.length ?? 'Geen'}</Typography>
                            {team.expirationDate && (
                                <Typography variant="h6">Gecertificeerd tot: {dayjs(team.expirationDate).format('DD-MM-YYYY')}</Typography>
                            )}
                        </Card>
                    </Grid>
                ))}
            </Grid>
            {errorTeams && (
                <Alert severity="error" mb="40px">{errorMessageTeams}</Alert>
            )}
        </Box>
    );
}

export default DogHandlerDashboard;