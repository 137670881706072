import { useState, useContext } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Box, Button, TextField, Alert, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { upsert } from "../../apiService";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

const initialValues = {
    date: '',
    passed: false,
}

const entitySchema = yup.object().shape({
    date: yup.string().required("Dit veld is verplicht"),
    passed: yup.bool(),
});

const CreateCertification = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { token } = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const handleOnCreate = async (values) => {
        try {
            const object = {
                eddTeamId: state.teamId,
                date: dayjs(values.date).format('YYYY-MM-DD'),
                passed: values.passed,
            }

            await upsert('certification-results', token, object);
            navigate('/resultaten', { state: { teamId: object.eddTeamId }});
        } catch (error) {
            setError(true);
            setErrorMessage('Er ging helaas wat fout tijdens het opslaan van het certificerings resultaat');
        } 
    };

    if (!state?.teamId) {
        return null;
    }

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Certificerings resultaat aanmaken</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Formik onSubmit={handleOnCreate} initialValues={initialValues} validationSchema={entitySchema}>
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Field name="startDate">
                                {({ field }) => (
                                    <DatePicker
                                        {...field}
                                        label="Datum certificering"
                                        value={values.date}
                                        format="DD-MM-YYYY"
                                        onChange={(date) => handleChange({ target: { name: "date", value: date } })}
                                        onBlur={handleBlur}
                                        renderInput={(params) => <TextField {...params} helperText={touched.date && errors.date} error={touched.date && Boolean(errors.date)} />}
                                    />
                                )}
                            </Field>
                        </LocalizationProvider>
                        <FormControlLabel control={
                            <Checkbox
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.passed} 
                                name="passed" 
                                error={!!touched.passed && !!errors.passed} 
                                helperText={touched.passed && errors.passed}
                            />
                        } label="Geslaagd" />
                    </Box>
                    <Box display="flex" justifyContent="start" mt="20px">
                        <Button type="submit" color="primary" variant="contain">
                            Aanmaken
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
        </Box>
    );
}

export default CreateCertification;