import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Box, Button, Alert, Typography, InputLabel, Select, MenuItem } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getById, getAll, upsert } from "../../apiService";

const entitySchema = yup.object().shape({
    locationId: yup.string().required("Dit veld is verplicht"),
});

const LocationCertificationDate = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { token } = useContext(AuthContext);
    const [certificationDate, setCertificationDate] = useState(null);
    const [locations, setLocations] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const handleOnUpdate = async (values) => {
        try {
            if (values.locationId === 'default') {
                values.locationId = null;
            }

            await upsert('certification-dates', token, values)
            navigate('/periode/overzicht', { state: { planPeriodId: values.planPeriodId }});
        } catch (error) {
            setError(true);
            setErrorMessage('Er ging helaas wat fout tijdens het toevoegen van een locatie aan een certificatie datum');
        } 
    };

    useEffect(() => {
        const getCertificationDate = async () => {
            setCertificationDate(await getById('certification-dates', token, state.certificationDateId));
        }

        const getLocations = async () => {
            setLocations(await getAll('locations', token));
        }

        if (!state?.certificationDateId) {
            navigate('/periode');
        }

        getCertificationDate();
        getLocations();
    }, [token, state, navigate]);

    if (!certificationDate || !locations) {
        return null;
    }

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Locatie wijzigen van certificatie datum</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Formik onSubmit={handleOnUpdate} initialValues={certificationDate} validationSchema={entitySchema}>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }}}>
                            <Box>
                                <InputLabel id="locationId" sx={{ position: 'absolute' }}>Locaties</InputLabel>
                                <Select
                                    labelId="locationId"
                                    type="text" 
                                    onBlur={handleBlur} 
                                    onChange={handleChange} 
                                    value={values.locationId ?? "default"} 
                                    name="locationId" 
                                    error={!!touched.locationId && !!errors.locationId} 
                                    helperText={touched.locationId && errors.locationId}
                                    sx={{ width: '100%' }}
                                >
                                    <MenuItem value="default" key="default">Geen locatie</MenuItem>
                                    {locations && locations.map(location => (
                                        <MenuItem value={location.id} key={location.id}>{location.street} {location.houseNumber}, {location.city} {location.zipCode}</MenuItem>
                                    ))} 
                                </Select>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="start" mt="20px">
                            <Button type="submit" color="primary" variant="contain">
                                Opslaan
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
}

export default LocationCertificationDate;