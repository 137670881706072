import { useTheme, tokens } from '../theme';
import { Box, Avatar, Tooltip } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from "dayjs";

const PeriodCard = ({ periods, onView, onRemove, onEdit }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette);

    return (
        <Box padding="12px" backgroundColor={colors.white} border={1} borderRadius="10px" borderColor={colors.font}>
            <table className="periodCard">
                <thead>
                    <tr>
                        <th>Start datum</th>
                        <th>Eind datum</th>
                        <th>Status</th>
                        <th>Acties</th>
                    </tr>
                </thead>
                <tbody>
                    {periods && periods.map((period, index) => (
                        <tr key={index}>
                            <td>{dayjs(period.startDate).format('DD-MM-YYYY')}</td>
                            <td>{dayjs(period.endDate).format('DD-MM-YYYY')}</td>
                            <td>{period.planPeriodStatus ? 'Gestart' : 'Niet gestart'}</td>
                            <td>
                                <Box display="flex">
                                    {onView && (
                                        <Tooltip title="Bekijken">
                                            <Avatar onClick={() => onView(period.id)} sx={{ bgcolor: colors.primary, color: colors.font, cursor: "pointer" }}>
                                                <VisibilityIcon />
                                            </Avatar>
                                        </Tooltip>
                                    )}
                                    {onEdit && (
                                        <Tooltip title="Bewerken">
                                            <Avatar onClick={() => onEdit(period.id)} sx={{ bgcolor: "transparent", color: colors.font, cursor: "pointer" }}>
                                                <EditIcon />
                                            </Avatar>
                                        </Tooltip>
                                    )}
                                    {onRemove && (
                                        <Tooltip title="Verwijderen">
                                            <Avatar onClick={() => onRemove(period.id)} sx={{ bgcolor: "transparent", color: colors.font, cursor: "pointer" }}>
                                                <DeleteIcon />
                                            </Avatar>
                                        </Tooltip>
                                    )}
                                </Box>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Box>
    );
};

export default PeriodCard;