import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Box, Button, TextField, Alert, Typography, Select, MenuItem, InputLabel } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { jwtDecode } from "jwt-decode";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getById, getAll, upsert } from "../../apiService";

const entitySchema = yup.object().shape({
    eddEntityId: yup.string().required("Dit veld is verplicht"),
    dogHandlerId: yup.string(),
    name: yup.string().required("Dit veld is verplicht"),
    chipNumber: yup.string().required("Dit veld is verplicht"),
});

const UpdateTeam = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { token } = useContext(AuthContext);
    const user = jwtDecode(token);
    const [team, setTeam] = useState(null);
    const [dogHandlers, setDogHandlers] = useState(null);
    const [error, setError] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const handleOnUpsert = async (values) => {
        try {
            let dogObject = {
                id: values.dogId,
                name: values.name,
                chipNumber: values.chipNumber,
            }

            await upsert('dogs', token, dogObject); 

            const object = {
                id: values.teamId,
                dogHandlerId: values.dogHandlerId,
                dogId: values.dogId,
                eddEntityId: values.eddEntityId,
            }

            await upsert('edd-teams', token, object);

            let  navigateState = {};

            if (user.role === 'BTBB-Medewerker') {
                navigateState = {
                    state: { 
                        entityId: values.eddEntityId 
                    },
                };
            }

            navigate('/team', navigateState);
        } catch (error) {
            setError(true);
            setErrorMessage('Er ging helaas wat fout tijdens het opslaan van het team');
        } 
    };

    useEffect(() => {
        const getTeam = async () => {
            setTeam(await getById('edd-teams', token, state.teamId));
        }

        const getDogHandlers = async () => {
            let allDogHandlers = await getAll('dog-handlers', token);

            allDogHandlers = allDogHandlers.filter(dogHandler => dogHandler.eddEntityId === team.eddEntityId);

            setDogHandlers(allDogHandlers);
        }

        if (!team && state?.teamId) {
            getTeam();
        }

        if (team && !dogHandlers) {
            getDogHandlers();
        }

        if (team) {
            setFormValues({
                eddEntityId: team.eddEntityId,
                teamId: team.id,
                dogId: team.dog.id,
                dogHandlerId: team.dogHandlerId,
                name: team.dog.name,
                chipNumber: team.dog.chipNumber,
            });
        }
    }, [token, state, team, dogHandlers]);

    if (!formValues) {
        return null;
    }

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Team bewerken</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Formik onSubmit={handleOnUpsert} initialValues={formValues} validationSchema={entitySchema} enableReinitialize>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }}}>
                            <TextField  
                                type="text" 
                                label="EntityId" 
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.eddEntityId} 
                                name="eddEntityId" 
                                error={!!touched.eddEntityId && !!errors.eddEntityId} 
                                helperText={touched.eddEntityId && errors.eddEntityId}
                                sx={{ display: 'none' }}
                            />
                            <TextField  
                                type="text" 
                                label="TeamId" 
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.teamId} 
                                name="teamId" 
                                error={!!touched.teamId && !!errors.teamId} 
                                helperText={touched.teamId && errors.teamId}
                                sx={{ display: 'none' }}
                            />
                            <TextField  
                                type="text" 
                                label="DogId" 
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.dogId} 
                                name="dogId" 
                                error={!!touched.dogId && !!errors.dogId} 
                                helperText={touched.dogId && errors.dogId}
                                sx={{ display: 'none' }}
                            />
                            <Box>
                                <InputLabel id="dogHandlerId" sx={{ position: 'absolute' }}>Hondengeleider</InputLabel>
                                <Select
                                    labelId="dogHandlerId"
                                    type="text" 
                                    onBlur={handleBlur} 
                                    onChange={handleChange} 
                                    value={values.dogHandlerId} 
                                    name="dogHandlerId" 
                                    error={!!touched.dogHandlerId && !!errors.dogHandlerId} 
                                    helperText={touched.dogHandlerId && errors.dogHandlerId}
                                    sx={{ width: '100%' }}
                                >
                                    {dogHandlers && dogHandlers.map(handler => (
                                        <MenuItem value={handler.id}>{handler.displayName}</MenuItem>
                                    ))} 
                                </Select>
                            </Box>
                            <TextField  
                                type="text" 
                                label="Naam van de hond" 
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.name} 
                                name="name" 
                                error={!!touched.name && !!errors.name} 
                                helperText={touched.name && errors.name}
                            />
                            <TextField  
                                type="text" 
                                label="Chip nummer van de hond" 
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.chipNumber} 
                                name="chipNumber" 
                                error={!!touched.chipNumber && !!errors.chipNumber} 
                                helperText={touched.chipNumber && errors.chipNumber}
                            />
                        </Box>
                        <Box display="flex" justifyContent="start" mt="20px">
                            <Button type="submit" color="primary" variant="contain">
                                Wijzigen
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
}

export default UpdateTeam;