import { useState, useContext, useEffect } from 'react';
import { jwtDecode  } from 'jwt-decode';
import { AuthContext } from 'react-oauth2-code-pkce';
import { ProSidebar, Menu, MenuItem} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Box, Typography, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { tokens } from '../../theme';
import { getAll } from '../../apiService';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import PetsIcon from '@mui/icons-material/Pets';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import EuroIcon from '@mui/icons-material/Euro';

const ItemSection = ({ title, allowedRoles }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette);
    const { token } = useContext(AuthContext);
    const role = token ? jwtDecode(token).roles : null;

    return (
        allowedRoles.includes(role) 
        ? (
            <Typography variant="h6" color={colors.font} sx={{ m: "15px 0 5px 20px" }}>{title}</Typography>
        ) : null
    )
}

const Item = ({ title, to, icon, selected, setSelected, allowedRoles }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette);
    const { token } = useContext(AuthContext);
    const role = token ? jwtDecode(token).roles : null;

    return (
        allowedRoles.includes(role) 
        ? (
            <MenuItem active={selected === title} style={{ color: colors.font }} onClick={() => setSelected(title)} icon={icon}>
                <Typography>{title}</Typography>
                <Link to={to} />
            </MenuItem>
        ) : null
    )
}

const Sidebar = () => {
    const theme = useTheme();
    const location = useLocation();
    const colors = tokens(theme.palette);
    const { token } = useContext(AuthContext);
    const [selected, setSelected] = useState('');
    const [user, setUser] = useState(null);

    useEffect(() => {
        const getSelectedMenuItem = () => {
            switch (location.pathname) {
                case '/entiteit':
                case '/entiteit/aanmaken':
                case '/entiteit/wijzigen':
                    return 'Entiteiten';
                case '/contactpersoon':
                case '/contactpersoon/aanmaken':
                case '/contactpersoon/wijzigen':
                    return 'Contactpersonen';
                case '/team':
                case '/team/aanmaken':
                case '/team/wijzigen':
                case '/resultaten':
                case '/resultaten/aanmaken':
                case '/resultaten/wijzigen':
                    return 'Teams';
                case '/hondengeleider':
                case '/hondengeleider/aanmaken':
                case '/hondengeleider/wijzigen':
                    return 'Hondengeleiders';
                case '/periode':
                case '/periode/aanmaken':
                case '/periode/wijzigen':
                case '/periode/overzicht':
                case '/periode/starten':
                case '/periode/locatie':
                    return 'Planperioden';
                case '/tijdslot':
                    return 'Tijdsloten';
                case '/planning':
                case '/planning/overzicht':
                case '/planning/inschrijven':
                    return 'Planning';
                case '/financieel':
                    return 'Financieel';
                case '/': 
                default:
                    return 'Dashboard';
            }
        }

        setSelected(getSelectedMenuItem);
    }, [location])

    useEffect(() => {
        const fetchUser = async () => {
            try {
                setUser(await getAll('users', token));
            } catch (error) {
                setUser({ DisplayName: 'Geen gebruiker bekend' });
            } 
        }

        if (!user) {
            fetchUser();
        }
    }, [token, user]);
    return (
        <Box sx={{
            boxShadow: 1,
            "& .pro-sidebar-inner": { 
                background: `${colors.white} !important`
            },
            "& .pro-icon-wrapper": {
                backgroundColor: "transparent !important"
            },
            "& .pro-inner-item": {
                padding: "5px 35px 5px 20px !important" 
            },
            "& .pro-inner-item:hover": {
                color: '#868dfb !important'
            },
            "& .pro-menu-item.active": {
                color: "#6870fa !important"
            }
            
        }}>
            <ProSidebar>
                <Menu iconShape="square">
                    {user && (
                        <Box mb="25px">
                            <Box textAlign="center">
                                <Typography variant="h2" color={colors.font} fontWeight="bold" sx={{ m: "10px 0 0 0" }}>{user.displayName}</Typography>
                                <Typography variant="h5" color={colors.primary}>{user.role}</Typography>
                            </Box>
                        </Box>
                    )}

                    <Box>
                        <Item title="Dashboard" to="/" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} allowedRoles={['BTBB-Medewerker', 'EDD-Contactpersoon', 'EDD-team', 'Erkend-agent']} />
                        <ItemSection title="Contacten" allowedRoles={['BTBB-Medewerker', 'EDD-Contactpersoon']} />
                        <Item title="Entiteiten" to="/entiteit" icon={<BusinessIcon />} selected={selected} setSelected={setSelected} allowedRoles={['BTBB-Medewerker']} />
                        <Item title="Contactpersonen" to="/contactpersoon" icon={<PeopleOutlinedIcon />} selected={selected} setSelected={setSelected} allowedRoles={['BTBB-Medewerker']} />
                        <Item title="Teams" to="/team" icon={<GroupsIcon />} selected={selected} setSelected={setSelected} allowedRoles={['BTBB-Medewerker', 'EDD-Contactpersoon']} />
                        <Item title="Hondengeleiders" to="/hondengeleider" icon={<PetsIcon />} selected={selected} setSelected={setSelected} allowedRoles={['BTBB-Medewerker', 'EDD-Contactpersoon']} />
                        <ItemSection title="Planning" allowedRoles={['BTBB-Medewerker', 'EDD-Contactpersoon']} />
                        <Item title="Planperioden" to="/periode" icon={<CalendarTodayOutlinedIcon />} selected={selected} setSelected={setSelected} allowedRoles={['BTBB-Medewerker']} />
                        <Item title="Tijdsloten" to="/tijdslot" icon={<CalendarTodayOutlinedIcon />} selected={selected} setSelected={setSelected} allowedRoles={[]} />
                        <Item title="Planning" to="/planning" icon={<CalendarTodayOutlinedIcon />} selected={selected} setSelected={setSelected} allowedRoles={['EDD-Contactpersoon']} />
                        <ItemSection title="Financieel" allowedRoles={['BTBB-Medewerker']} />
                        <Item title="Financieel" to="/financieel" icon={<EuroIcon />} selected={selected} setSelected={setSelected} allowedRoles={['BTBB-Medewerker']} />
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
}

export default Sidebar;