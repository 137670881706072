import { Box, Alert, Typography, Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTitle } from "../../providers/TitleProvider";
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { getById, getAll, remove } from '../../apiService';
import Card from "../../components/Card";

const Contacts = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { token } = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fetched, setFetched] = useState(false); 
    const [entity, setEntity] = useState(null)
    const [contacts, setContacts] = useState(null);
    const [contactToRemove, setContactToRemove] = useState(null);
    const [open, setOpen] = useState(false);
    const { updateTitle } = useTitle();

    const handleOnRemove = async (id) => {
        if (open) {
            try {
                await remove('edd-contacts', token, id);
                setOpen(false);
                setFetched(false);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het verwijderen van de contactpersoon');
            } 
        } else {
            setContactToRemove(await getById('edd-contacts', token, id));
            setOpen(true);
        }
    }

    const handleOnNotRemove = () => {
        setOpen(false);
    };

    const handleOnEdit = async (id) => {
        navigate('/contactpersoon/wijzigen', { state: { contactId: id }});
    }

    const handleOnCreate = () => {
        navigate('/contactpersoon/aanmaken');
    }

    useEffect(() => {
        if (entity) {
            updateTitle('Contactpersonen - ' + entity?.name);
        } else {
            updateTitle('Contactpersonen');
        }
    }, [updateTitle, entity]);

    useEffect(() => {
        const getEntity = async () => {
            try {
                setEntity(await getById('edd-entities', token, state.entityId));
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de entiteit');
            } 

            window.history.replaceState({}, document.title);
        };

        const getAllContacts = async () => {
            let fetchedContacts;

            try {
                fetchedContacts = await getAll('edd-contacts', token);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de contactpersonen');
            } 

            if (entity) {
                fetchedContacts = fetchedContacts.filter(contact => contact.eddEntityId === entity.id);
                fetchedContacts.forEach(contact => {
                    contact.entityName = entity.name;
                });
            } else {
                const entities = await getAll('edd-entities', token);
                fetchedContacts.forEach(contact => {
                    contact.entityName = entities.find(entity => entity.id === contact.eddEntityId).name;
                });
            }

            setContacts(fetchedContacts);
            setFetched(true);
        }

        if (state?.entityId && !entity) {
            getEntity();
        }

        getAllContacts();
    }, [token, fetched, state, entity]);

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Contactpersonen overzicht</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Grid container spacing={2}>
                {contacts && contacts.map(contact => (
                    <Grid item xs={4}  key={contact.id}>
                        <Card title={contact.displayName} onRemove={() => handleOnRemove(contact.id)} onEdit={() => handleOnEdit(contact.id)}>
                            <Typography variant="h6">Emailadres: {contact.email}</Typography>
                            <Typography variant="h6">Entiteit: {contact.entityName}</Typography>
                        </Card>
                    </Grid>
                ))}
                <Grid item xs={4}>
                    <Button onClick={handleOnCreate}>Nieuw contactpersoon toevoegen</Button>
                </Grid>
            </Grid>
            <Dialog 
                open={open}
                onClose={handleOnNotRemove}
                aria-labelledby="alert-title"
                aria-describedby="alert-description"
            >
                <DialogTitle id="alert-title">
                    <Typography variant="h3">
                        Weet u dit zeker?
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-description">
                        <Typography variant="h5">
                            Weet u zeker dat u {contactToRemove?.displayName} wil verwijderen?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnNotRemove} variant="outlined">Annuleren</Button>
                    <Button onClick={() => handleOnRemove(contactToRemove?.id)}>Verwijderen</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default Contacts;