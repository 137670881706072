import { Box, Alert, Typography, Grid, Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTitle } from "../../providers/TitleProvider";
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { getById, getAll, upsert } from '../../apiService';
import dayjs from "dayjs";
import TimeSlotContact from "../../components/TimeSlotContact";

const OverviewPlanning = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { token } = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [planPeriod, setPlanPeriod] = useState(null);
    const [certificationDates, setCertificationDates] = useState(null);
    const [timeSlotToReturn, setTimeSlotToReturn] = useState(null);
    const [open, setOpen] = useState(false);
    const [fetched, setFetched] = useState(false);
    const { updateTitle } = useTitle();

    const handleOnReturn = async (id) => {
        if (open) {
            try {
                const object = timeSlotToReturn;
                object.eddEntityId = null;

                await upsert('time-slots', token, object);
                setFetched(false);
                setOpen(false);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het teruggeven van het tijdslot');
            } 
        } else {
            setTimeSlotToReturn(await getById('time-slots', token, id));
            setOpen(true);
        }
    }

    const handleOnNotReturn = () => {
        setOpen(false);
    }

    const handleOnAdd = (id) => {
        navigate('/planning/inschrijven', { state: { planPeriodId: planPeriod.id, timeSlotId: id }});
    }

    const handleOnCancel = async (id) => {
        try {
            const slot = await getById('time-slots', token, id);
            const slotDay = dayjs(slot.certificationDate.date);
            const currentDate = dayjs();

            if (slotDay.diff(currentDate, 'day') >= 14) {
                slot.eddTeamId = null;

                await upsert('time-slots', token, slot);
                setFetched(false);
            } else {
                setError(true);
                setErrorMessage('Een tijdslot kan helaas niet twee weken voor de certificeringsdatum geannuleerd worden.');
            }
        } catch (error) {
            setError(true);
            setErrorMessage('Er ging helaas wat fout tijdens het annuleren van het tijdslot');
        }
    }

    useEffect(() => {
        updateTitle('Planning');
    }, [updateTitle]);

    useEffect(() => {
        const getPlanPeriod = async () => {
            try {
                setPlanPeriod(await getById('plan-periods', token, state.planPeriodId));
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de planperiode');
            } 
        }

        const getCertificationDates = async () => {
            try {
                let dates = await getAll('certification-dates', token);
                const slots = await getAll('time-slots', token);
                const locations = await getAll('locations', token);
                const entity = await getAll('edd-entities', token);

                dates = dates.filter(date => date.planPeriodId === planPeriod.id);
                dates.forEach(async date => {
                    const matchingSlots = slots.filter(slot => slot.certificationDateId === date.id && slot.eddEntityId === entity[0].id);

                    matchingSlots.forEach(async slot => {
                        if (slot.eddTeam) {
                            slot.eddTeam.dogHandler = await getById('dog-handlers', token, slot.eddTeam.dogHandlerId);
                            slot.eddTeam.dog = await getById('dogs', token, slot.eddTeam.dogId);
                        }
                    });

                    matchingSlots.sort((a, b) => {
                        const dateA = new Date(`1970-01-01T${a.startTime}`);
                        const dateB = new Date(`1970-01-01T${b.startTime}`);
                        return dateA - dateB;
                    });

                    date.timeSlots = matchingSlots;

                    date.location = locations.find(location => location.id === date.locationId);
                });
                dates.sort((a, b) => {
                    const dateA = dayjs(a.date);
                    const dateB = dayjs(b.date);
                    return dateA - dateB;
                });

                setCertificationDates(dates);
                setFetched(true);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de certificerings datums');
            } 
        }

        if (state?.planPeriodId && !planPeriod) {
            getPlanPeriod();
        }

        if (planPeriod && !certificationDates) {
            getCertificationDates();
        }
    }, [token, fetched, state, planPeriod, certificationDates]);
    
    return (
        <Box m="20px">
            <Box display="flex">
                <Typography variant="h2" mb="40px" mr="10px">Beschikbare sloten</Typography>
            </Box>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Box mb="20px">
                <Grid container spacing={2}>
                    {certificationDates && certificationDates.map(date => (
                        <>
                            {date.timeSlots.map(timeSlot => (
                                <Grid item xs={4}>
                                    <TimeSlotContact date={date}  timeSlot={timeSlot} onAdd={() => handleOnAdd(timeSlot.id)} onReturn={() => handleOnReturn(timeSlot.id)} onCancel={() => handleOnCancel(timeSlot.id)} />
                                </Grid>
                            ))}
                        </>
                    ))}
                </Grid>
            </Box>
            <Dialog 
                open={open}
                onClose={handleOnNotReturn}
                aria-labelledby="alert-title"
                aria-describedby="alert-description"
            >
                <DialogTitle id="alert-title">
                    <Typography variant="h3">
                    Weet u dit zeker?
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-description">
                        <Typography variant="h5">
                            Weet u zeker dat u het tijdslot van {dayjs(timeSlotToReturn?.certificationDate.date).format('DD-MM-YYYY')} {timeSlotToReturn?.startTime} - {timeSlotToReturn?.endTime} wil teruggeven?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnNotReturn} variant="outlined">Annuleren</Button>
                    <Button onClick={() => handleOnReturn(timeSlotToReturn?.id)}>Teruggeven</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default OverviewPlanning;