import { useTheme, tokens } from '../theme';
import { Box, Typography, Avatar, Tooltip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const Card = ({ title, onRemove, onEdit, children }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette);

    return (
        <Box padding="12px" backgroundColor={colors.white} border={1} borderRadius="10px" borderColor={colors.font}>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h4">{title}</Typography>
                <Box display="flex">
                    {onRemove && (
                        <Tooltip title="Verwijderen">
                            <Avatar onClick={onRemove} sx={{ bgcolor: "transparent", color: colors.font, cursor: "pointer" }}>
                                <DeleteIcon />
                            </Avatar>
                        </Tooltip>
                    )}
                    {onEdit && (
                        <Tooltip title="Bewerken">
                            <Avatar onClick={onEdit} sx={{ bgcolor: colors.primary, color: colors.font, cursor: "pointer" }}>
                                <EditIcon />
                            </Avatar>
                        </Tooltip>
                    )}
                </Box>
            </Box>
            {children}
        </Box>
    );
};

export default Card;