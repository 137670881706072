import React, { useEffect, useContext } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { AuthContext } from 'react-oauth2-code-pkce';
import { jwtDecode } from "jwt-decode";
import { useTitle } from "../../providers/TitleProvider";
import { Box } from "@mui/material";
import EmployeeDashboard from "./Employee";
import ContactDashboard from "./Contact";
import DogHandlerDashboard from "./DogHandler";

const Dashboard = () => {
    const location = useLocation();
    const { token } = useContext(AuthContext);
    const user = jwtDecode(token);
    const { updateTitle } = useTitle();

    useEffect(() => {
        updateTitle('Dashboard');
    }, [updateTitle]);

    return (
        <Box m="20px">
            {(() => {
                switch (user.roles) {
                    case 'BTBB-Medewerker':
                        return <EmployeeDashboard />
                    case 'EDD-Contactpersoon':
                        return <ContactDashboard />
                    case 'Hondengeleider':
                        return <DogHandlerDashboard />
                    default:
                        return <Navigate to="/onbevoegd" state={{ from: location }} replace />
                }
            })()}
        </Box>
    );
}

export default Dashboard;