import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Box, Button, TextField, Alert, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { jwtDecode } from "jwt-decode";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getById, upsert } from "../../apiService";

const entitySchema = yup.object().shape({
    eddEntityId: yup.string().required("Dit veld is verplicht"),
    firstName: yup.string().required("Dit veld is verplicht"),
    lastName: yup.string().required("Dit veld is verplicht"),
    email: yup.string().email("Dit is geen emailadres").required("Dit veld is verplicht"),
});

const UpdateDogHandler = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { token } = useContext(AuthContext);
    const user = jwtDecode(token);
    const [dogHandler, setDogHandler] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const handleOnUpdate = async (values) => {
        try {
            values.displayName = values.firstName + ' ' + values.lastName;

            await upsert('dog-handlers', token, values);
            
            let  navigateState = {};

            if (user.role === 'BTBB-Medewerker') {
                navigateState = {
                    state: { 
                        entityId: values.eddEntityId 
                    },
                };
            }

            navigate('/hondengeleider', navigateState);
        } catch (error) {
            setError(true);
            setErrorMessage('Er ging helaas wat fout tijdens het opslaan van de hondengeleider');
        } 
    };

    useEffect(() => {
        const getDogHandler = async () => {
            setDogHandler(await getById('dog-handlers', token, state.dogHandlerId));
        }

        if (!state?.dogHandlerId) {
            navigate('/hondengeleider');
        }

        getDogHandler();
    }, [token, state, navigate]);

    if (!dogHandler) {
        return null;
    }

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Hondengeleider bewerken</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Formik onSubmit={handleOnUpdate} initialValues={dogHandler} validationSchema={entitySchema} enableReinitialize>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }}}>
                            <TextField  
                                type="text" 
                                label="EntityId" 
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.eddEntityId} 
                                name="eddEntityId" 
                                error={!!touched.eddEntityId && !!errors.eddEntityId} 
                                helperText={touched.eddEntityId && errors.eddEntityId}
                                sx={{ display: 'none' }}
                            />
                            <TextField  
                                type="text" 
                                label="ID" 
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.id} 
                                name="id" 
                                error={!!touched.id && !!errors.id} 
                                helperText={touched.id && errors.id}
                                sx={{ display: 'none' }}
                            />
                            <TextField  
                                type="text" 
                                label="Voornaam" 
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.firstName} 
                                name="firstName" 
                                error={!!touched.firstName && !!errors.firstName} 
                                helperText={touched.firstName && errors.firstName}
                            />
                            <TextField  
                                type="text" 
                                label="Achternaam" 
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.lastName} 
                                name="lastName" 
                                error={!!touched.lastName && !!errors.lastName} 
                                helperText={touched.lastName && errors.lastName}
                            />
                            <TextField  
                                type="text" 
                                label="Emailadres" 
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.email} 
                                name="email" 
                                error={!!touched.email && !!errors.email} 
                                helperText={touched.email && errors.email}
                            />
                        </Box>
                        <Box display="flex" justifyContent="start" mt="20px">
                            <Button type="submit" color="primary" variant="contain">
                                Wijzigen
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
}

export default UpdateDogHandler;