import { Box, Alert, Typography, Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTitle } from "../../providers/TitleProvider";
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { jwtDecode } from "jwt-decode";
import { getById, getAll, remove } from '../../apiService';
import Certification from "../../components/Certification";

const Certifications = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { token } = useContext(AuthContext);
    const user = jwtDecode(token);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fetched, setFetched] = useState(false); 
    const [team, setTeam] = useState(null);
    const [certificationToRemove, setCertificationToRemove] = useState(null);
    const [open, setOpen] = useState(false);
    const { updateTitle } = useTitle();

    const handleOnRemove = async (id) => {
        if (open) {
            try {
                await remove('certification-results', token, id);
                setFetched(false);
                setOpen(false);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het verwijderen van het certificaat resultaat');
            } 
        } else {
            setCertificationToRemove(await getById('certification-results', token, id));
            setOpen(true);
        }
    }

    const handleOnNotRemove = () => {
        setOpen(false);
    };

    const handleOnCreate = (id) => {
        navigate('/resultaten/aanmaken', { state: { teamId: id}});
    }

    useEffect(() => {
        if (team) {
            updateTitle('Teams ' + team?.dogHandler?.displayName + ' resultaten');
        } else {
            updateTitle('Team resultaten');
        }
    }, [updateTitle, team]);

    useEffect(() => {
        const getTeam = async () => {
            try {
                setTeam(await getById('edd-teams', token, state.teamId));
                setFetched(true);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van het team');
            }
        }

        if (!state?.teamId) {
            navigate('/team');
        }

        getTeam();
    }, [token, fetched, state, navigate]);

    useEffect(() => {
        const assignCertifications = async () => {
            if (!team) {
                return null;
            }
    
            let certifications;
    
            try {
                certifications = await getAll('certification-results', token);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de certificerings resultaten');
            } 
    
            certifications.forEach(certification => {
                if (team.certificationResults === null) {
                    team.certificationResults = [];
                }
                
                if (team.certificationResults.find(result => result.id === certification.id)) {
                    return;
                }

                if (certification.eddTeamId === team.id) {
                    const newTeam = team;
                    newTeam.certificationResults = [
                        ...team.certificationResults,
                        certification
                    ];
    
                    setTeam(newTeam);
                }
            });
        };

        assignCertifications();
    }, [token, team]);

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Team certificerings resultaten</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            {!team?.certificationResults && (
                <Typography variant="h3" mb="10px">Er zijn voor dit team nog geen ceritificerings pogingen bekend.</Typography>
            )}
            <Grid container spacing={2}>
                {team?.certificationResults && team.certificationResults.map(result => (
                    <Grid item xs={4} key={result.id}>
                        <Certification date={result.date} expirationDate={result.expirationDate} passed={result.passed} onDelete={handleOnRemove} />
                    </Grid>
                ))}
                {user.roles === 'BTBB-Medewerker' && (
                    <Grid item xs={4}>
                        <Button onClick={() => handleOnCreate(team.id)}>Nieuwe certificeringspoging toevoegen</Button>
                    </Grid>
                )}
            </Grid>
            <Dialog 
                open={open}
                onClose={handleOnNotRemove}
                aria-labelledby="alert-title"
                aria-describedby="alert-description"
            >
                <DialogTitle id="alert-title">
                    <Typography variant="h3">
                        Weet u dit zeker?
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-description">
                        <Typography variant="h5">
                            Weet u zeker dat u het certificaat van {certificationToRemove?.date} wil verwijderen?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnNotRemove} variant="outlined">Annuleren</Button>
                    <Button onClick={() => handleOnRemove(certificationToRemove?.id)}>Verwijderen</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default Certifications;