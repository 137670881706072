import { useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Box, Button, TextField, Alert, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { upsert } from "../../apiService";

const initialValues = {
    name: '',
    street: '',
    houseNumber: '',
    zipCode: '',
    city: '',
}

const entitySchema = yup.object().shape({
    name: yup.string().required("Dit veld is verplicht"),
    street: yup.string().required("Dit veld is verplicht"),
    houseNumber: yup.string().required("Dit veld is verplicht"),
    zipCode: yup.string().required("Dit veld is verplicht"),
    city: yup.string().required("Dit veld is verplicht"),
});

const CreateEntity = () => {
    const navigate = useNavigate();
    const { token } = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const handleOnCreate = async (values) => {
        try {
            let location = {
                street: values.street,
                houseNumber: values.houseNumber,
                zipCode: values.zipCode,
                city: values.city,
            };

            location = await upsert('locations', token, location);

            const entity = {
                name: values.name,
                locationId: location.id
            };

            await upsert('edd-entities', token, entity)
            navigate('/entiteit');
        } catch (error) {
            setError(true);
            setErrorMessage('Er ging helaas wat fout tijdens het opslaan van de entiteit');
        } 
    };

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Entiteit aanmaken</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Formik onSubmit={handleOnCreate} initialValues={initialValues} validationSchema={entitySchema}>
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }}}>
                        <TextField  
                            type="text" 
                            label="Naam" 
                            onBlur={handleBlur} 
                            onChange={handleChange} 
                            value={values.name} 
                            name="name" 
                            error={!!touched.name && !!errors.name} 
                            helperText={touched.name && errors.name}
                        />
                        <TextField  
                            type="text" 
                            label="Straat" 
                            onBlur={handleBlur} 
                            onChange={handleChange} 
                            value={values.street} 
                            name="street" 
                            error={!!touched.street && !!errors.street} 
                            helperText={touched.street && errors.street}
                        />
                        <TextField  
                            type="text" 
                            label="Huisnummer" 
                            onBlur={handleBlur} 
                            onChange={handleChange} 
                            value={values.houseNumber}
                            name="houseNumber"
                            error={!!touched.houseNumber && !!errors.houseNumber} 
                            helperText={touched.houseNumber && errors.houseNumber}
                        />
                        <TextField  
                            type="text" 
                            label="Postcode" 
                            onBlur={handleBlur} 
                            onChange={handleChange} 
                            value={values.zipCode} 
                            name="zipCode" 
                            error={!!touched.zipCode && !!errors.zipCode} 
                            helperText={touched.zipCode && errors.zipCode}
                        />
                        <TextField  
                            type="text" 
                            label="Stad" 
                            onBlur={handleBlur} 
                            onChange={handleChange} 
                            value={values.city} 
                            name="city" 
                            error={!!touched.city && !!errors.city} 
                            helperText={touched.city && errors.city}
                        />
                    </Box>
                    <Box display="flex" justifyContent="start" mt="20px">
                        <Button type="submit" color="primary" variant="contain">
                            Aanmaken
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
        </Box>
    );
}

export default CreateEntity;