import { Box, IconButton, useTheme, Typography } from '@mui/material';
import { tokens } from '../../theme';
import { useTitle } from '../../providers/TitleProvider';
import LogoutIcon from '@mui/icons-material/Logout';

const Topbar = () => {
    const { title } = useTitle();
    const theme = useTheme();
    const colors = tokens(theme.palette);

    const logout = () => {
        localStorage.clear();
        window.location.href = '/';
    }

    return (
        <Box display="flex" p={2} justifyContent="space-between" backgroundColor={colors.white} sx={{ boxShadow: 1 }}>
            <Typography variant="h2" color={colors.font} fontWeight="bold">{title}</Typography>
            <Box display="flex">                
                <IconButton onClick={logout}>
                    <LogoutIcon />
                </IconButton>
            </Box>
        </Box>
    );
}

export default Topbar;