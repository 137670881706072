import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequiredRole = ({ allowedRoles, role }) => {
    const location = useLocation();

    return (
        allowedRoles.includes(role)
            ? <Outlet />
            : <Navigate to="/onbevoegd" state={{ from: location }} replace />
    );
}

export default RequiredRole;