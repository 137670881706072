import { Box, Alert, Typography, Grid, Tooltip, Avatar } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme, tokens } from "../../theme";
import { useTitle } from "../../providers/TitleProvider";
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { getById, getAll } from '../../apiService';
import dayjs from "dayjs";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import TimeSlot from "../../components/TimeSlot";

const OverviewPlanPeriod = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette);
    const navigate = useNavigate();
    const { state } = useLocation();
    const { token } = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [planPeriod, setPlanPeriod] = useState(null);
    const [certificationDates, setCertificationDates] = useState(null);
    const { updateTitle } = useTitle();

    const handleOnStart = async (id) => {
        navigate('/periode/starten', { state: { planPeriodId: id }});
    }

    const handleOnLocation = async (id) => {
        navigate('/periode/locatie', { state: { certificationDateId: id }});
    }

    useEffect(() => {
        updateTitle('Planperiode');
    }, [updateTitle]);

    useEffect(() => {
        const getPlanPeriod = async () => {
            try {
                setPlanPeriod(await getById('plan-periods', token, state.planPeriodId));
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de planperiode');
            } 
        }

        const getCertificationDates = async () => {
            try {
                let dates = await getAll('certification-dates', token);
                const slots = await getAll('time-slots', token);
                const locations = await getAll('locations', token);

                dates = dates.filter(date => date.planPeriodId === planPeriod.id);
                dates.forEach(date => {
                    const matchingSlots = slots.filter(slot => slot.certificationDateId === date.id);
                    const matchingLocation = locations.find(location => location.id === date.locationId);

                    matchingSlots.forEach(async slot => {
                        if (slot.eddTeam) {
                            slot.eddTeam.dogHandler = await getById('dog-handlers', token, slot.eddTeam.dogHandlerId);
                            slot.eddTeam.dog = await getById('dogs', token, slot.eddTeam.dogId);
                        }
                    });

                    matchingSlots.sort((a, b) => {
                        const dateA = new Date(`1970-01-01T${a.startTime}`);
                        const dateB = new Date(`1970-01-01T${b.startTime}`);
                        return dateA - dateB;
                    });

                    date.timeSlots = matchingSlots;
                    date.location = matchingLocation;
                });

                dates.sort((a, b) => {
                    const dateA = dayjs(a.date);
                    const dateB = dayjs(b.date);
                    return dateA - dateB;
                });

                setCertificationDates(dates);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de certificerings datums');
            } 
        }

        if (state?.planPeriodId && !planPeriod) {
            getPlanPeriod();
        }

        if (planPeriod && !certificationDates) {
            getCertificationDates();
        }
    }, [token, state, planPeriod, certificationDates]);
    
    return (
        <Box m="20px">
            <Box display="flex">
                <Typography variant="h2" mb="40px" mr="10px">Planperiode overzicht</Typography>
                <Tooltip title="Starten">
                    <Avatar onClick={() => handleOnStart(planPeriod?.id)} sx={{ bgcolor: colors.primary, color: colors.font, cursor: "pointer" }}>
                        <PlayArrowIcon />
                    </Avatar>
                </Tooltip>
            </Box>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Box mb="20px">
                <Grid container spacing={2}>
                    {certificationDates && certificationDates.map(date => (
                        <Grid item xs={6}>
                            <Box display="flex">
                                <Box mb="20px">
                                    <Typography variant="h3" mr="10px">Certificeringsdag: {dayjs(date.date).format('DD-MM-YYYY')}</Typography>
                                    <Typography variant="h6">Locatie: 
                                        {date?.location ? (
                                            <> {date.location.street} {date.location.houseNumber}, {date.location.city} {date.location.zipCode}</>
                                        ) : (
                                            ' Onbekend'
                                        )} 
                                    </Typography>
                                </Box>
                                <Tooltip title="Locatie toevoegen">
                                    <Avatar onClick={() => handleOnLocation(date.id)} sx={{ bgcolor: colors.primary, color: colors.font, cursor: "pointer" }}>
                                        {date?.location ? (
                                            <EditLocationIcon />
                                        ) : (
                                            <AddLocationIcon />
                                        )}
                                        
                                    </Avatar>
                                </Tooltip>
                            </Box>
                            
                            {date.timeSlots.map(timeSlot => (
                                <Box display="flex" justifyContent="space-between">
                                    <TimeSlot timeSlot={timeSlot} />
                                </Box>
                            ))}
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
}

export default OverviewPlanPeriod;