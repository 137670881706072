import { useTheme, tokens } from '../theme';
import { Box, Typography, Avatar, Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import dayjs from "dayjs";

const TimeSlotContact = ({ date, timeSlot, onAdd, onReturn, onCancel }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette);

    return (
        <Box padding="12px" backgroundColor={colors.white} border={1} borderRadius="10px" borderColor={colors.font}>
            <Box display="flex" alignItems="center" mb="10px" justifyContent="space-between">
                <Box>
                    <Typography variant="h4">Datum: {dayjs(date.date).format('DD-MM-YYYY')} {timeSlot?.startTime} - {timeSlot?.endTime}</Typography>
                    <Typography variant="h6">Locatie: {date.location ? date.location.street + ' ' + date.location.houseNumber + ', ' + date.location.city + ' ' + date.location.zipCode : 'Nog onbekend'}</Typography>
                </Box>
                <Box display="flex" ml="10px">
                    {onReturn && !timeSlot.eddTeamId && (
                        <Tooltip title="Teruggeven">
                            <Avatar onClick={onReturn} sx={{ bgcolor: "transparent", color: colors.font, cursor: "pointer" }}>
                                <KeyboardReturnIcon />
                            </Avatar>
                        </Tooltip>
                    )}
                    {onCancel && timeSlot.eddTeamId && (
                        <Tooltip title="Annuleren">
                            <Avatar onClick={onCancel} sx={{ bgcolor: colors.primary, color: colors.font, cursor: "pointer" }}>
                                <CancelIcon />
                            </Avatar>
                        </Tooltip>
                    )}
                    {onAdd && !timeSlot.eddTeamId && (
                        <Tooltip title="Toevoegen">
                            <Avatar onClick={onAdd} sx={{ bgcolor: colors.primary, color: colors.font, cursor: "pointer" }}>
                                <AddIcon />
                            </Avatar>
                        </Tooltip>
                    )}
                </Box>
            </Box>
            <Typography variant="h6">Ingeschreven team: {timeSlot?.eddTeam ? timeSlot?.eddTeam?.dogHandler?.displayName + ' en ' + timeSlot?.eddTeam?.dog?.name : 'Nog geen team ingeschreven'}</Typography>
        </Box>
    );
};

export default TimeSlotContact;