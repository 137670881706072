const BASE_URL = 'https://btbbplanprocess.azurewebsites.net';

const callAPI = async (endpoint, token, method = 'GET', data = null) => {
    const config = {
        method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    };

    if (data) {
        config.body = JSON.stringify(data);
    }

    try {
        const response = await fetch(`${BASE_URL}/${endpoint}`, config);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        if (method === 'delete') {
            return response;
        }

        return response.json();
    } catch (error) {
        throw error;
    }
};

export const upsert = async (endpoint, token, data) => {
    try {
        if (data.id) {
            return await callAPI(`api/v1/${endpoint}/${data.id}`, token, 'put', data);
        } else {
            return await callAPI(`api/v1/${endpoint}`, token, 'post', data);
        }
    } catch (error) {
        throw error;
    }
}

export const getAll = async (endpoint, token) => {
    try {
        return await callAPI(`api/v1/${endpoint}`, token);
    } catch (error) {
        throw error;
    }
}

export const getById = async (endpoint, token, id) => {
    try {
        return await callAPI(`api/v1/${endpoint}/${id}`, token);
    } catch (error) {
        throw error;
    }
}

export const remove = async (endpoint, token, id) => {
    try {
        return await callAPI(`api/v1/${endpoint}/${id}`, token, 'delete');
    } catch (error) {
        throw error;
    }
}

export const startPlanPeriod = async (token, data) => {
    try {
        return await callAPI(`api/v1/plan-periods/${data.id}/start`, token, 'put', data);
    } catch (error) {
        throw error;
    }
}

export const getDistributionsByPlanPeriod = async (token, id) => {
    try {
        return await callAPI(`api/v1/distributions/?planPeriod=${id}`, token, 'get');
    } catch (error) {
        throw error;
    }
}

export const getBillingByPeriod = async (token, from, to) => {
    const config = {
        method: 'get',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    };

    try {
        const response = await fetch(`${BASE_URL}/api/v1/billing/?from=${from}&to=${to}`, config);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return {url: window.URL.createObjectURL(await response.blob()), name: `billing_${from}_${to}.xlsx`}
    } catch (error) {
        throw error;
    }
}