import { useTheme, tokens } from '../theme';
import { Box, Typography, Tooltip, Avatar } from "@mui/material";
import dayjs from "dayjs";
import DeleteIcon from '@mui/icons-material/Delete';

const Certification = ({ date, expirationDate, passed, onRemove }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette);

    return (
        <Box padding="12px" backgroundColor={colors.white} border={2} borderColor={passed ? '#008000' : '#FF0000'} borderRadius="10px">
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Typography variant="h3" mb="10px">Certificeringsdatum: {dayjs(date).format('DD-MM-YYYY')}</Typography>
                <Typography variant="h4" mb="10px">Certificering status: {passed ? 'Geslaagd' : 'Gezakt'}</Typography>
                {expirationDate && (
                    <Typography variant="h4" mb="10px">Certificering geldig tot: {dayjs(expirationDate).format('DD-MM-YYYY')}</Typography>
                )}
                <Box display="flex">
                    {onRemove && (
                        <Tooltip title="Verwijderen">
                            <Avatar onClick={onRemove} sx={{ bgcolor: "transparent", color: colors.font, cursor: "pointer" }}>
                                <DeleteIcon />
                            </Avatar>
                        </Tooltip>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Certification;