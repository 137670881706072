import { Box, Alert, Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, Select, MenuItem } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTitle } from "../../providers/TitleProvider";
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { getById, getAll, upsert, startPlanPeriod, getDistributionsByPlanPeriod, remove } from '../../apiService';
import dayjs from "dayjs";

const StartPlanPeriod = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { token } = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [entities, setEntities] = useState(null);
    const [planPeriod, setPlanPeriod] = useState(null);
    const [certificationDates, setCertificationDates] = useState(null);
    const [distributions, setDistributions] = useState(null);
    const [open, setOpen] = useState(false);
    const { updateTitle } = useTitle();

    const handleOnStart = async () => {
        if (open) {
            try {
                certificationDates.forEach(date => {
                    date.timeSlots.forEach(async timeSlot => {
                        if (timeSlot.eddEntityId === 'removed') {
                            await remove('time-slots', token, timeSlot.id);
                        } else {
                            await upsert('time-slots', token, timeSlot);
                        }
                    })
                });
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het opslaan van de tijdsloten verdeling');
            }

            try {
                if (!planPeriod.planPeriodStatus) {
                    await startPlanPeriod(token, planPeriod);
                }
            } catch (error) {
                // Fout kan genegeerd worden
            }

            navigate('/periode/overzicht', { state: { planPeriodId: planPeriod.id }});
            setOpen(false);
        } else {
            setOpen(true);
        }
    }
    
    const handleOnNotStart = () => {
        setOpen(false);
    };

    const handleOnChange = (e, certificationDateId) => {
        const updatedDates = certificationDates.map(date => {
            if (date.id === certificationDateId) {
                const updatedTimeSlots = date.timeSlots.map(slot => {
                    if (slot.id === e.name) {
                        return { ...slot, eddEntityId: e.value !== 'default' ? e.value : null };
                    }
                    return slot;
                });
                return { ...date, timeSlots: updatedTimeSlots };
            }
            return date;
        });

        setCertificationDates(updatedDates);
    };

    const getEntityNameById = (eddEntityId) => {
       const foundEntity = entities.find(entity => entity.id === eddEntityId);
       return foundEntity.name;
    }

    const getTotalTimeSlots = (eddEntityId) => {
        if (!certificationDates) {
            return null;
        }

        let total = 0;

        certificationDates.forEach(date => {
            date.timeSlots.forEach(timeSlot => {
                if (timeSlot.eddEntityId === eddEntityId) {
                    total++;
                }
            });
        });

        return total;
    }

    useEffect(() => {
        updateTitle('Planperiode');
    }, [updateTitle]);

    useEffect(() => {
        const getPlanPeriod = async () => {
            try {
                setPlanPeriod(await getById('plan-periods', token, state.planPeriodId));
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de planperiode');
            } 
        }

        const getEntities = async () => {
            try {
                setEntities(await getAll('edd-entities', token));
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de entiteiten');
            } 
        }

        const getCertificationDates = async () => {
            try {
                let dates = await getAll('certification-dates', token);
                const slots = await getAll('time-slots', token);

                dates = dates.filter(date => date.planPeriodId === planPeriod.id);
                dates.forEach(date => {
                    const matchingSlots = slots.filter(slot => slot.certificationDateId === date.id);
                    matchingSlots.sort((a, b) => {
                        const dateA = new Date(`1970-01-01T${a.startTime}`);
                        const dateB = new Date(`1970-01-01T${b.startTime}`);
                        return dateA - dateB;
                    });

                    date.timeSlots = matchingSlots;
                });
                dates.sort((a, b) => {
                    const dateA = dayjs(a.date);
                    const dateB = dayjs(b.date);
                    return dateA - dateB;
                });

                setCertificationDates(dates);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de certificerings datums');
            } 
        }

        const getDistributions = async () => {
            try {
                setDistributions(await getDistributionsByPlanPeriod(token, planPeriod.id));
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de verdeelsleutel');
            } 
        }

        if (state?.planPeriodId && !planPeriod) {
            getPlanPeriod();
        }

        if (!entities) {
            getEntities();
        }

        if (planPeriod && !certificationDates && !distributions) {
            getCertificationDates();
            getDistributions();
        }
    }, [token, state, planPeriod, certificationDates, distributions, entities]);
    
    return (
        <Box m="20px">
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Typography variant="h2" mb="40px">Planperiode starten</Typography>
                    {error && (
                        <Alert severity="error" mb="40px">{errorMessage}</Alert>
                    )}
                    <Box mb="20px">
                        <Grid container spacing={2}>
                            {certificationDates && certificationDates.map(date => (
                                <Grid item xs={6}>
                                    <Typography variant="h3" mb="20px">Certificeringsdag: {dayjs(date.date).format('DD-MM-YYYY')}</Typography>
                                    {date.timeSlots.map(timeSlot => (
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant="h4" mb="20px">Tijdslot: {timeSlot.startTime} - {timeSlot.endTime}</Typography>
                                            <Select
                                                type="text" 
                                                value={timeSlot.eddEntityId ?? "default"} 
                                                onChange={(e) => handleOnChange(e.target, date.id)}
                                                name={timeSlot.id} 
                                            >
                                                <MenuItem value="default">Beschikbaar voor alle entiteiten</MenuItem>
                                                {entities && entities.map(entity => (
                                                    <MenuItem value={entity.id}>{entity.name}</MenuItem>
                                                ))} 
                                                <MenuItem value="removed">Dit tijdslot verwijderen!</MenuItem>
                                            </Select>
                                        </Box>
                                    ))}
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Button onClick={handleOnStart}>Periode starten</Button>
                    <Dialog 
                        open={open}
                        onClose={handleOnNotStart}
                        aria-labelledby="alert-title"
                        aria-describedby="alert-description"
                    >
                        <DialogTitle id="alert-title">
                            <Typography variant="h3">
                                Weet u dit zeker?
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-description">
                                <Typography variant="h5">
                                    Weet u zeker dat de periode van {dayjs(planPeriod?.startDate).format('DD-MM-YYYY')} tot {dayjs(planPeriod?.endDate).format('DD-MM-YYYY')} met deze verdeling wil starten?
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleOnNotStart} variant="outlined">Annuleren</Button>
                            <Button onClick={() => handleOnStart()}>Starten</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="h3" mb="20px">verdeelsleutel per entiteit</Typography>  
                    <Box mb="20px" flexDirection="column"> 
                        {distributions && distributions.map(distribution => (
                            <Box mb="20px">
                                <Typography variant="h6" mb="5px">{getEntityNameById(distribution.eddEntityId)}</Typography>
                                <Typography>Recht op {distribution.allowedAllocations} tijdsloten</Typography>
                                {distribution.wantedAllocations && (
                                    <Typography>Wil graag {distribution.wantedAllocations} tijdsloten</Typography>
                                )}
                                <Typography>{getTotalTimeSlots(distribution.eddEntityId)} tijdslot(en) toegewezen</Typography>
                            </Box>
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default StartPlanPeriod;