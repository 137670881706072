export const OAuthConfig = {
    clientId: '1511f2c7-7d48-4caa-83ed-01202d4654d5',
    redirectUri: 'https://www.btbb-planproces.nl/',
    authorizationEndpoint: 'https://btbboauthservice.azurewebsites.net/connect/authorize',
    tokenEndpoint: 'https://btbboauthservice.azurewebsites.net/connect/token',
    logoutUrl: 'https://btbboauthservice.azurewebsites.net/connect/logout',
    scope: 'api://1511f2c7-7d48-4caa-83ed-01202d4654d5/user_impersonation openid profile offline_access',
    onRefreshTokenExpire: (event) => window.confirm('Session expired. Refresh page to continue using the site?') && event.login(),
    autoLogin: false,
};

// export const config = {
//     clientId: '1511f2c7-7d48-4caa-83ed-01202d4654d5',
//     redirectUri: 'http://localhost:3000/',
//     authorizationEndpoint: ' https://localhost:7216/connect/authorize',
//     tokenEndpoint: ' https://localhost:7216/connect/token',
//     logoutUrl: ' https://localhost:7216/connect/logout',
//     scope: 'api://1511f2c7-7d48-4caa-83ed-01202d4654d5/user_impersonation openid profile offline_access',
//     onRefreshTokenExpire: (event) => window.confirm('Session expired. Refresh page to continue using the site?') && event.login(),
//     autoLogin: false,
// };