import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Box, Button, TextField, Alert, Typography, Select, MenuItem, InputLabel } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { jwtDecode } from "jwt-decode";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getAll, upsert } from "../../apiService";

const initialValues = {
    eddEntityId: '',
    dogHandlerId: '',
    name: '',
    chipNumber: '',
}

const entitySchema = yup.object().shape({
    eddEntityId: yup.string().required("Dit veld is verplicht"),
    dogHandlerId: yup.string(),
    name: yup.string().required("Dit veld is verplicht"),
    chipNumber: yup.string().required("Dit veld is verplicht"),
});

const CreateTeam = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { token } = useContext(AuthContext);
    const user = jwtDecode(token);
    const [entities, setEntities] = useState(null);
    const [dogHandlers, setDogHandlers] = useState(null);
    const [error, setError] = useState(false);
    const [eddEntityId, setEddEntityId] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const handleOnCreate = async (values) => {
        try {
            let dogObject = {
                name: values.name,
                chipNumber: values.chipNumber,
            }

            dogObject = await upsert('dogs', token, dogObject); 

            const object = {
                dogHandlerId: values.dogHandlerId,
                dogId: dogObject.id,
                eddEntityId: values.eddEntityId,
            }

            await upsert('edd-teams', token, object);

            let  navigateState = {};

            if (user.role === 'BTBB-Medewerker') {
                navigateState = {
                    state: { 
                        entityId: values.eddEntityId 
                    },
                };
            }

            navigate('/team', navigateState);
        } catch (error) {
            setError(true);
            setErrorMessage('Er ging helaas wat fout tijdens het opslaan van het team');
        } 
    };

    const updateDogHandlers = async (values) => {
        if (eddEntityId !== values.eddEntityId) {
            let handlers = await getAll('dog-handlers', token);
            setDogHandlers(handlers.filter(handler => handler.eddEntityId === values.eddEntityId));
            values.dogHandlerId = '';
            setEddEntityId(values.eddEntityId);
        }
    }

    useEffect(() => {
        const getDogHandlers = async () => {
            setDogHandlers(await getAll('dog-handlers', token));
        }

        const getEntities = async () => {
            setEntities(await getAll('edd-entities', token))
        }

        if (!dogHandlers) {
            getDogHandlers();
        }

        if (!entities) {
            getEntities();
        }

        if (entities?.length === 1) {
            initialValues.eddEntityId = entities[0].id;
        }

        if (dogHandlers?.length === 1) {
            initialValues.dogHandlerId = dogHandlers[0].id;
        }
    }, [token, state, dogHandlers, entities]);

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Team aanmaken</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Formik onSubmit={handleOnCreate} initialValues={initialValues} validationSchema={entitySchema} enableReinitialize>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }}}>
                            <Box sx={{ display: entities?.length === 1 ? 'none' : 'initial' }} >
                                <InputLabel id="eddEntityId" sx={{ position: 'absolute' }}>Entiteit</InputLabel>
                                <Select
                                    labelId="eddEntityId"
                                    type="text" 
                                    onBlur={handleBlur} 
                                    onChange={handleChange} 
                                    value={values.eddEntityId} 
                                    name="eddEntityId" 
                                    error={!!touched.eddEntityId && !!errors.eddEntityId} 
                                    helperText={touched.eddEntityId && errors.eddEntityId}
                                    sx={{ width: '100%' }}
                                >
                                    {entities && entities.map(entity => (
                                        <MenuItem value={entity.id}>{entity.name}</MenuItem>
                                    ))} 
                                </Select>
                            </Box>
                            <Box>
                                <InputLabel id="dogHandlerId" sx={{ position: 'absolute' }}>Hondengeleider</InputLabel>
                                <Select
                                    labelId="dogHandlerId"
                                    type="text" 
                                    onBlur={handleBlur} 
                                    onChange={handleChange} 
                                    value={values.dogHandlerId} 
                                    name="dogHandlerId" 
                                    error={!!touched.dogHandlerId && !!errors.dogHandlerId} 
                                    helperText={touched.dogHandlerId && errors.dogHandlerId}
                                    sx={{ width: '100%' }}
                                >
                                    {dogHandlers && updateDogHandlers(values) && dogHandlers.map(handler => (
                                        <MenuItem value={handler.id}>{handler.displayName}</MenuItem>
                                    ))} 
                                </Select>
                            </Box>
                            <TextField  
                                type="text" 
                                label="Naam van de hond" 
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.name} 
                                name="name" 
                                error={!!touched.name && !!errors.name} 
                                helperText={touched.name && errors.name}
                            />
                            <TextField  
                                type="text" 
                                label="Chip nummer van de hond" 
                                onBlur={handleBlur} 
                                onChange={handleChange} 
                                value={values.chipNumber} 
                                name="chipNumber" 
                                error={!!touched.chipNumber && !!errors.chipNumber} 
                                helperText={touched.chipNumber && errors.chipNumber}
                            />
                        </Box>
                        <Box display="flex" justifyContent="start" mt="20px">
                            <Button type="submit" color="primary" variant="contain">
                                Aanmaken
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
}

export default CreateTeam;