import { Box, Alert, Typography, Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useTitle } from "../../providers/TitleProvider";
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { getById, getAll, remove } from '../../apiService';
import Card from "../../components/Card";

const Entities = () => {
    const navigate = useNavigate();
    const { token } = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fetched, setFetched] = useState(false); 
    const [entities, setEntities] = useState(null);
    const [entityToRemove, setEntityToRemove] = useState(null);
    const [open, setOpen] = useState(false);
    const { updateTitle } = useTitle();

    const handleOnRemove = async (id) => {
        if (open) {
            try {
                await remove('edd-entities', token, id);
                setFetched(false);
                setOpen(false);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het verwijderen van de entiteit');
            } 
        } else {
            setEntityToRemove(await getById('edd-entities', token, id));
            setOpen(true);
        }
    }
    
    const handleOnNotRemove = () => {
        setOpen(false);
    };

    const handleOnEdit = async (id) => {
        navigate('/entiteit/wijzigen', { state: { entityId: id }});
    }

    const handleOnCreate = () => {
        navigate('/entiteit/aanmaken');
    }

    const handleOnShowContacts = (id) => {
        navigate('/contactpersoon', { state: { entityId: id }});
    }

    const handleOnShowTeams = (id) => {
        navigate('/team', { state: { entityId: id }});
    }

    const handleOnDogHandlers = (id) => {
        navigate('/hondengeleider', { state: { entityId: id }});
    }

    useEffect(() => {
        updateTitle('Entiteiten');
    }, [updateTitle]);

    useEffect(() => {
        const getAllEntities = async () => {
            try {
                setEntities(await getAll('edd-entities', token));
                setFetched(true);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de entiteiten');
            } 
        }

        getAllEntities();
    }, [token, fetched]);

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Entiteiten overzicht</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Grid container spacing={2}>
                {entities && entities.map(entity => (
                    <Grid item xs={4} key={entity.id}>
                        <Card title={entity.name} onRemove={() => handleOnRemove(entity.id)} onEdit={() => handleOnEdit(entity.id)}>
                            <Typography variant="h6">Adres: {entity.street} {entity.houseNumber}</Typography>
                            <Typography variant="h6">Postcode: {entity.zipCode}</Typography>
                            <Typography variant="h6">Stad: {entity.city}</Typography>
                            <br />
                            <Typography variant="h6" sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => handleOnShowContacts(entity.id)}>Contactpersonen inzien</Typography>
                            <Typography variant="h6" sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => handleOnShowTeams(entity.id)}>Teams inzien</Typography>
                            <Typography variant="h6" sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => handleOnDogHandlers(entity.id)}>Hondengeleiders inzien</Typography>
                        </Card>
                    </Grid>
                ))}
                <Grid item xs={4}>
                    <Button onClick={handleOnCreate}>Nieuwe entiteit toevoegen</Button>
                </Grid>
            </Grid>
            <Dialog 
                open={open}
                onClose={handleOnNotRemove}
                aria-labelledby="alert-title"
                aria-describedby="alert-description"
            >
                <DialogTitle id="alert-title">
                    <Typography variant="h3">
                        Weet u dit zeker?
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-description">
                        <Typography variant="h5">
                            Weet u zeker dat u {entityToRemove?.name} met alle bijhorende contactpersonen en teams wil verwijderen?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnNotRemove} variant="outlined">Annuleren</Button>
                    <Button onClick={() => handleOnRemove(entityToRemove?.id)}>Verwijderen</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default Entities;