import React, { useContext } from 'react';
import { jwtDecode  } from 'jwt-decode';
import { OAuthConfig } from './config/OAuthConfig'
import { AuthContext, AuthProvider } from 'react-oauth2-code-pkce';
import { useTheme, tokens } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { TitleProvider } from './providers/TitleProvider';
import RequiredRole from './components/RequiredRole';
import Unauthorized from './scenes/unauthorized';
import Topbar from './scenes/global/Topbar';
import Sidebar from './scenes/global/Sidebar';
import Login from './scenes/login/Login';
import Entities from './scenes/entities';
import CreateEntity from './scenes/entities/Create';
import UpdateEntity from './scenes/entities/Update';
import Contacts from './scenes/contacts';
import Teams from './scenes/teams';
import DogHandlers from './scenes/dog-handlers';
import PlanPeriods from './scenes/plan-periods';
import Certifications from './scenes/certification';
import StartPlanPeriod from './scenes/plan-periods/Start';
import UpdateContact from './scenes/contacts/Update';
import CreateContact from './scenes/contacts/Create';
import UpdateDogHandler from './scenes/dog-handlers/Update';
import CreateDogHandler from './scenes/dog-handlers/Create';
import CreateTeam from './scenes/teams/Create';
import UpdateTeam from './scenes/teams/Update';
import CreatePlanPeriod from './scenes/plan-periods/Create';
import UpdatePlanPeriod from './scenes/plan-periods/Update';
import CreateCertification from './scenes/certification/Create';
import Dashboard from './scenes/dashboard';
import OverviewPlanPeriod from './scenes/plan-periods/Overview';
import Planning from './scenes/planning';
import OverviewPlanning from './scenes/planning/Overview';
import LocationCertificationDate from './scenes/plan-periods/Location';
import SubscribePlanning from './scenes/planning/Subscribe';
import Financial from './scenes/finance';

function App() {
    const theme = useTheme();

    return (
        <AuthProvider authConfig={OAuthConfig}>
            <ThemeProvider theme={theme}>
                <TitleProvider>
                    <CssBaseline />
                    <Controller /> 
                </TitleProvider>
            </ThemeProvider>
        </AuthProvider>
    );
}

export default App;

const Controller = () => {
    const { token } = useContext(AuthContext);
    const role = token ? jwtDecode(token).roles : null;
    const theme = useTheme();
    const colors = tokens(theme.palette);
    
    return (
        <div className="app" style={{ backgroundColor: token ? colors.grey[50] : colors.blueAccent }}>
            {!token ? (
                <Login />
            ) : (
                <>
                    <Sidebar />
                    <main className="content">
                        <Topbar />
                        <Routes>
                            {/* Public routes */}
                            <Route path="/onbevoegd" element={<Unauthorized />} />
                            <Route path="/" element={<Dashboard />} />

                            {/* Protected routes */}
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/entiteit" element={<Entities />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/entiteit/aanmaken" element={<CreateEntity />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/entiteit/wijzigen" element={<UpdateEntity />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/contactpersoon" element={<Contacts />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/contactpersoon/aanmaken" element={<CreateContact />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/contactpersoon/wijzigen" element={<UpdateContact />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker', 'EDD-Contactpersoon']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/team" element={<Teams />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker', 'EDD-Contactpersoon']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/team/aanmaken" element={<CreateTeam />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker', 'EDD-Contactpersoon']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/team/wijzigen" element={<UpdateTeam />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker', 'EDD-Contactpersoon']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/resultaten" element={<Certifications />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker', 'EDD-Contactpersoon']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/resultaten/aanmaken" element={<CreateCertification />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker', 'EDD-Contactpersoon']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/hondengeleider" element={<DogHandlers />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker', 'EDD-Contactpersoon']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/hondengeleider/aanmaken" element={<CreateDogHandler />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker', 'EDD-Contactpersoon']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/hondengeleider/wijzigen" element={<UpdateDogHandler />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/periode" element={<PlanPeriods />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/periode/aanmaken" element={<CreatePlanPeriod />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/periode/wijzigen" element={<UpdatePlanPeriod />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/periode/overzicht" element={<OverviewPlanPeriod />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/periode/starten" element={<StartPlanPeriod />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/periode/locatie" element={<LocationCertificationDate />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['BTBB-Medewerker']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/financieel" element={<Financial />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['EDD-Contactpersoon']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/planning" element={<Planning />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['EDD-Contactpersoon']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/planning/overzicht" element={<OverviewPlanning />} />
                            </Route>
                            <Route element={
                                <RequiredRole 
                                    allowedRoles={['EDD-Contactpersoon']} 
                                    role={role} 
                                />}
                            >
                                <Route path="/planning/inschrijven" element={<SubscribePlanning />} />
                            </Route>
                        </Routes>
                    </main>
                </>
            )}
        </div>
    );
}