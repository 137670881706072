import React, { useEffect } from "react";
import { useTitle } from "../../providers/TitleProvider";
import { Box, Typography } from "@mui/material";


const Unauthorized = () => {
    const { updateTitle } = useTitle();

    useEffect(() => {
        updateTitle('Niet bevoegd');
    }, [updateTitle]);

    return (
        <Box m="20px">
            <Typography variant="h2">Je bent helaas niet bevoegd om deze pagina te bezoeken</Typography>
        </Box>
    );
}

export default Unauthorized;