import { Box, Alert, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useTitle } from "../../providers/TitleProvider";
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { getAll } from '../../apiService';
import PlanningCard from "../../components/PlanningCard";

const Planning = () => {
    const navigate = useNavigate();
    const { token } = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fetched, setFetched] = useState(false); 
    const [planPeriods, setPlanPeriods] = useState(null);
    const { updateTitle } = useTitle();

    const handleOnView = async (id) => {
        navigate('/planning/overzicht', { state: { planPeriodId: id }});
    }

    useEffect(() => {
        updateTitle('Planning');
    }, [updateTitle]);

    useEffect(() => {
        const getAllPlanPeriods = async () => {
            try {
                const currentDate = new Date();
                const planPeriods = await getAll('plan-periods', token);

                setPlanPeriods(planPeriods.filter(period => {
                    const startDate = new Date(period.startDate);
                    const endDate = new Date(period.endDate);
                    return ((startDate <= currentDate && endDate >= currentDate) || startDate > currentDate) && period.planPeriodStatus;
                }));

                setFetched(true);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de planperioden');
            } 
        }

        getAllPlanPeriods();
    }, [token, fetched]);

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Planning overzicht</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Box mb="20px">
                {planPeriods && planPeriods.length ? (
                    <PlanningCard periods={planPeriods} onView={handleOnView} />
                ) : (
                    <Typography>Er is momenteel geen perioden waar in gepland kan worden</Typography>
                )}
            </Box>
        </Box>
    );
}

export default Planning;