import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Box, Button, TextField, Alert, Typography } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getById, getAll, upsert } from "../../apiService";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

const entitySchema = yup.object().shape({
    startDate: yup.string().required("Dit veld is verplicht"),
    endDate: yup.string().required("Dit veld is verplicht"),
});

const UpdatePlanPeriod = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { token } = useContext(AuthContext);
    const [planPeriod, setPlanPeriod] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const checkOverlap = async (newPlanPeriod) => {
        const newStartDate = newPlanPeriod.startDate;
        const newEndDate = newPlanPeriod.endDate;
        const planPeriods = await getAll('plan-periods', token);

        for (let i = 0; i < planPeriods.length; i++) {
            if (planPeriods[i].id === newPlanPeriod.id) {
                return false;
            }

            const startDate = dayjs(planPeriods[i].startDate);
            const endDate = dayjs(planPeriods[i].endDate);

            if (
                (newStartDate.isBefore(startDate) && newStartDate.isAfter(endDate))
                || (newEndDate.isBefore(startDate) && newEndDate.isAfter(endDate))
                || (newStartDate.isBefore(startDate) && newEndDate.isAfter(endDate))
            ) {
                return true;
            }

            return false;
        }
    }

    const handleOnUpdate = async (values) => {
        try {
            const object = {
                startDate: dayjs(values.startDate),
                endDate: dayjs(values.endDate),
                id: values.id,
            }

            if (await checkOverlap(object)) {
                setError(true);
                setErrorMessage('Deze periode heeft overlap met andere periodes, pas de datum aan');
                return null;
            }

            await upsert('plan-periods', token, object)
            navigate('/periode');
        } catch (error) {
            setError(true);
            setErrorMessage('Er ging helaas wat fout tijdens het opslaan van de planperiode');
        } 
    };

    useEffect(() => {
        if (!state?.planPeriodId) {
            navigate('/periode');
        }

        const getPlanPeriod = async () => {
            let period = await getById('plan-periods', token, state.planPeriodId);
            period.startDate = dayjs(period.startDate);
            period.endDate = dayjs(period.endDate);
            setPlanPeriod(period);
        }

        getPlanPeriod();
    }, [token, state, navigate]);

    if (!planPeriod) {
        return null;
    }

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Planperiode bewerken</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Formik onSubmit={handleOnUpdate} initialValues={planPeriod} validationSchema={entitySchema} enableReinitialize>
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }}}>
                        <TextField  
                            type="text" 
                            label="Id" 
                            onBlur={handleBlur} 
                            onChange={handleChange} 
                            value={values.id} 
                            name="id" 
                            error={!!touched.id && !!errors.id} 
                            helperText={touched.id && errors.id}
                            sx={{ display: 'none' }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Field name="startDate">
                                {({ field }) => (
                                    <DatePicker
                                        {...field}
                                        label="Begin datum"
                                        value={values.startDate}
                                        format="DD-MM-YYYY"
                                        onChange={(date) => handleChange({ target: { name: "startDate", value: date } })}
                                        onBlur={handleBlur}
                                        renderInput={(params) => <TextField {...params} helperText={touched.startDate && errors.startDate} error={touched.startDate && Boolean(errors.startDate)} />}
                                    />
                                )}
                            </Field>
                            <Field name="endDate">
                                {({ field }) => (
                                    <DatePicker
                                        {...field}
                                        label="Eind datum"
                                        value={values.endDate}
                                        format="DD-MM-YYYY"
                                        onChange={(date) => handleChange({ target: { name: "endDate", value: date } })}
                                        onBlur={handleBlur}
                                        renderInput={(params) => <TextField {...params} helperText={touched.endDate && errors.endDate} error={touched.endDate && Boolean(errors.endDate)} />}
                                    />
                                )}
                            </Field>
                        </LocalizationProvider> 
                    </Box>
                    <Box display="flex" justifyContent="start" mt="20px">
                        <Button type="submit" color="primary" variant="contain">
                            Wijzigen
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
        </Box>
    );
}

export default UpdatePlanPeriod;