import { Box, Alert, Typography, Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTitle } from "../../providers/TitleProvider";
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { getById, getAll, remove } from '../../apiService';
import Card from "../../components/Card";

const DogHandlers = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { token } = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fetched, setFetched] = useState(null);
    const [entity, setEntity] = useState(null);
    const [dogHandlers, setDogHandlers] = useState(null);
    const [dogHandlerToRemove, setDogHandlerToRemove] = useState(null);
    const [open, setOpen] = useState(false);
    const { updateTitle } = useTitle();

    const handleOnRemove = async (id) => {
        if (open) {
            try {
                await remove('dog-handlers', token, id);
                setOpen(false);
                setFetched(false);  
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het verwijderen van de hondengeleider');
            } 
        } else {
            setDogHandlerToRemove(await getById('dog-handlers', token, id));
            setOpen(true);
        }
    }
    
    const handleOnNotRemove = () => {
        setOpen(false);
    };

    const handleOnEdit = async (id) => {
        navigate('/hondengeleider/wijzigen', { state: { dogHandlerId: id }});
    }

    const handleOnCreate = () => {
        navigate('/hondengeleider/aanmaken');
    }

    const handleOnShowTeams = (id) => {
        navigate('/team', { state: { dogHandlerId: id }});
    }

    useEffect(() => {
        if (entity) {
            updateTitle('Hondengeleiders - ' + entity?.name);
        } else {
            updateTitle('Hondengeleiders');
        }
    }, [updateTitle, entity]);

    useEffect(() => {
        const getEntity = async () => {
            try {
                setEntity(await getById('edd-entities', token, state.entityId));
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de entiteit');
            } 

            window.history.replaceState({}, document.title);
        };

        const getAllDogHandlers = async () => {
            try {
                if (state?.dogHandlerId) {
                    let fetchedDogHandler = await getById('dog-handlers', token, state.dogHandlerId);
                    const entity = await getById('edd-entities', token, fetchedDogHandler.eddEntityId);
                    fetchedDogHandler.entityName = entity.name;

                    setDogHandlers([fetchedDogHandler]);
                } else {
                    let allDogHandlers = await getAll('dog-handlers', token);

                    if (entity) {
                        allDogHandlers = allDogHandlers.filter(dogHandler => dogHandler.eddEntityId === entity.id);
                        allDogHandlers.forEach(handler => {
                            handler.entityName = entity.name;
                        });
                    } else {
                        const entities = await getAll('edd-entities', token);
                        allDogHandlers.forEach(handler => {
                            handler.entityName = entities.find(entity => entity.id === handler.eddEntityId).name;
                        });
                    } 

                    setDogHandlers(allDogHandlers);
                } 
                setFetched(true);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de hondengeleiders');
            }
        }

        if (state?.entityId && !entity && !state?.dogHandlerId) {
            getEntity();
        }

        getAllDogHandlers();
    }, [token, fetched, state, entity]);

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Hondengeleiders overzicht</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Grid container spacing={2}>
                {dogHandlers && dogHandlers.map(dogHandler => (
                    <Grid item xs={4} key={dogHandler.id}>
                        <Card title={dogHandler.displayName} onRemove={() => handleOnRemove(dogHandler.id)} onEdit={() => handleOnEdit(dogHandler.id)}>
                            <Typography variant="h6" sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => handleOnShowTeams(dogHandler.id)}>Teams inzien</Typography>
                            <Typography variant="h6">Emailadres: {dogHandler.email}</Typography>
                            <Typography variant="h6">Entiteit: {dogHandler.entityName}</Typography>
                        </Card>
                    </Grid>
                ))}
                <Grid item xs={4}>
                    <Button onClick={handleOnCreate}>Nieuwe hondengeleiders toevoegen</Button>
                </Grid>
            </Grid>
            <Dialog 
                open={open}
                onClose={handleOnNotRemove}
                aria-labelledby="alert-title"
                aria-describedby="alert-description"
            >
                <DialogTitle id="alert-title">
                    <Typography variant="h3">
                    Weet u dit zeker?
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-description">
                        <Typography variant="h5">
                            Weet u zeker dat u {dogHandlerToRemove?.displayName} wil verwijderen met de bijhorende teams?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnNotRemove} variant="outlined">Annuleren</Button>
                    <Button onClick={() => handleOnRemove(dogHandlerToRemove?.id)}>Verwijderen</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default DogHandlers;