import { Box, Typography } from "@mui/material";

const TimeSlot = ({ timeSlot }) => {

    return (
        <Box mb="20px">
            <Typography variant="h4">Tijdslot: {timeSlot?.startTime} - {timeSlot?.endTime}</Typography>
            <Typography variant="h6">Toewezen aan: {timeSlot?.eddEntity?.name ?? 'Nog niet toegewezen'}</Typography>
            <Typography variant="h6">Ingeschreven team: {timeSlot?.eddTeam ? timeSlot?.eddTeam?.dogHandler?.displayName + ' en ' + timeSlot?.eddTeam?.dog?.name : 'Nog geen team ingeschreven'}</Typography>
        </Box>
    );
};

export default TimeSlot;