import { useTheme, tokens } from '../theme';
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

const TimeSlotHandler = ({ timeSlot }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette);

    return (
        <Box padding="12px" backgroundColor={colors.white} border={1} borderRadius="10px" borderColor={colors.font}>
            <Typography variant="h4">Tijdslot: {dayjs(timeSlot.certificationDate.date).format('DD-MM-YYYY')} {timeSlot?.startTime} - {timeSlot?.endTime}</Typography>
            <Typography variant="h6" mt="10px">Team: {timeSlot?.eddTeam.dogHandler.displayName} en {timeSlot?.eddTeam.dog.name}</Typography>
            <Typography variant="h6">Locatie: {timeSlot?.certificationDate?.location ? timeSlot?.certificationDate.location.street + ' ' + timeSlot?.certificationDate.location.houseNumber + ', ' + timeSlot?.certificationDate.location.city + ' ' + timeSlot?.certificationDate.location.zipCode : 'Nog onbekend'}</Typography>
        </Box>
    );
};

export default TimeSlotHandler;