import React, { createContext, useContext, useState } from 'react';

const TitleContext = createContext();

export const useTitle = () => {
    return useContext(TitleContext);
};

export const TitleProvider = ({ children }) => {
    const [title, setTitle] = useState('');

    const updateTitle = (newTitle) => {
        setTitle(newTitle);
    };

    return (
        <TitleContext.Provider value={{ title, updateTitle }}>
            {children}
        </TitleContext.Provider>
    );
};