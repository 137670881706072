import { Box, Alert, Typography, Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTitle } from "../../providers/TitleProvider";
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { getById, getAll, remove } from '../../apiService';
import Card from "../../components/Card";

const Teams = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { token } = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fetched, setFetched] = useState(false);
    const [entity, setEntity] = useState(null);
    const [dogHandler, setDogHandler] = useState(null);
    const [teams, setTeams] = useState(null);
    const [teamToRemove, setTeamToRemove] = useState(null);
    const [open, setOpen] = useState(false);
    const { updateTitle } = useTitle();

    const handleOnRemove = async (id) => {
        if (open) {
            try {
                await remove('edd-teams', token, id);
                setOpen(false);
                setFetched(false);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het verwijderen van het team');
            } 
        } else {
            setTeamToRemove(await getById('edd-teams', token, id));
            setOpen(true);
        }
    }
    
    const handleOnNotRemove = () => {
        setOpen(false);
    };

    const handleOnEdit = async (id) => {
        navigate('/team/wijzigen', { state: { teamId: id }});
    }

    const handleOnShowCertifications = (id) => {
        navigate('/resultaten', { state: { teamId: id }});
    }

    const handleOnCreate = () => {
        navigate('/team/aanmaken');
    }

    const handleOnShowDogHandler = (id) => {
        navigate('/hondengeleider', { state: { dogHandlerId: id }});
    }

    useEffect(() => {
        if (dogHandler) {
            updateTitle('Teams - ' + dogHandler?.displayName);
        } else if (entity) {
            updateTitle('Teams - ' + entity?.name);
        } else {
            updateTitle('Teams');
        }
    }, [updateTitle, entity, dogHandler]);

    useEffect(() => {
        const getDogHandler = async () => {
            try {
                setDogHandler(await getById('dog-handlers', token, state.dogHandlerId));
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de hondengeleider');
            } 

            window.history.replaceState({}, document.title);
        };

        const getEntity = async () => {
            try {
                setEntity(await getById('edd-entities', token, state.entityId));
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de entiteit');
            } 

            window.history.replaceState({}, document.title);
        };

        const getAllTeams = async () => {
            try {
                let fetchedTeams = await getAll('edd-teams', token);

                if (dogHandler) {
                    fetchedTeams = fetchedTeams.filter(team => team.dogHandlerId === dogHandler.id);
                    const entity = await getById('edd-entities', token, fetchedTeams[0].eddEntityId);
                    fetchedTeams.forEach(team => {
                        team.entityName = entity.name;
                    });
                } else if (entity) {
                    fetchedTeams = fetchedTeams.filter(team => team.eddEntityId === entity.id);
                    fetchedTeams.forEach(team => {
                        team.entityName = entity.name;
                    });
                } else {
                    const entities = await getAll('edd-entities', token);
                    fetchedTeams.forEach(team => {
                        team.entityName = entities.find(entity => entity.id === team.eddEntityId).name;
                    });
                }

                setTeams(fetchedTeams);

                setFetched(true);
            } catch (error) {
                setError(true);
                setErrorMessage('Er ging helaas wat fout tijdens het ophalen van de hondengeleiders');
            }
        }

        if (state?.dogHandlerId && !dogHandler) {
            getDogHandler();
        }

        if (state?.entityId && !entity && !dogHandler) {
            getEntity();
        }

        getAllTeams();
    }, [token, state, fetched, entity, dogHandler]);

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Teams overzicht</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Grid container spacing={2}>
                {teams && teams.map(team => (
                    <Grid item xs={4} key={team.id}>
                        <Card title={`${team.dogHandler.displayName} - ${team.dog.name}`} onRemove={() => handleOnRemove(team.id)} onEdit={() => handleOnEdit(team.id)}>
                            <Typography variant="h6">Chipnummer: {team.dog.chipNumber}</Typography>
                            <Typography variant="h6">Entiteit: {team.entityName}</Typography>
                            <Typography variant="h6" sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => handleOnShowDogHandler(team.dogHandler.id)}>Hondengeleider inzien</Typography>
                            <Typography variant="h6" sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => handleOnShowCertifications(team.id)}>Certificerings resultaten inzien</Typography>
                        </Card>
                    </Grid>
                ))}
                <Grid item xs={4}>
                    <Button onClick={handleOnCreate}>Nieuw team toevoegen</Button>
                </Grid>
            </Grid>
            <Dialog 
                open={open}
                onClose={handleOnNotRemove}
                aria-labelledby="alert-title"
                aria-describedby="alert-description"
            >
                <DialogTitle id="alert-title">
                    <Typography variant="h3">
                    Weet u dit zeker?
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-description">
                        <Typography variant="h5">
                        Weet u zeker dat u het team van {teamToRemove?.dogHandler?.displayName} en {teamToRemove?.dog?.name} wil verwijderen? De hond zal hierbij ook uit het systeem worden verwijderd.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnNotRemove} variant="outlined">Annuleren</Button>
                    <Button onClick={() => handleOnRemove(teamToRemove?.id)}>Verwijderen</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default Teams;