import { useState, useContext, useEffect, useRef } from "react";
import { useTitle } from "../../providers/TitleProvider";
import { AuthContext } from 'react-oauth2-code-pkce';
import { Box, Button, TextField, Alert, Typography } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { getBillingByPeriod } from "../../apiService";

const initialValues = {
    startDate: '',
    endDate: '',
}

const entitySchema = yup.object().shape({
    startDate: yup.string().required("Dit veld is verplicht"),
    endDate: yup.string().required("Dit veld is verplicht"),
});

const Financial = () => {
    const { token } = useContext(AuthContext);
    const linkRef = useRef(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const { updateTitle } = useTitle();
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const checkInPast = async (values) => {
        const startDate = dayjs(values.startDate);
        const endDate = dayjs(values.endDate);
        const currentDate = dayjs();

        if (startDate.isBefore(currentDate, 'day') && endDate.isBefore(currentDate, 'day') && startDate.isBefore(endDate, 'day')) {
            return false;
        }
        return true;
    }

    const handleOnCreate = async (values, { resetForm }) => {
        try {
            if (await checkInPast(values)) {
                setError(true);
                setErrorMessage('De periode moet in het verleden liggen');
                return null;
            }

            const file = await getBillingByPeriod(token, values.startDate.format('YYYY-MM-DD'), values.endDate.format('YYYY-MM-DD'));

            linkRef.current.href = file.url;
            linkRef.current.download = file.name;
            linkRef.current.click();

            resetForm({values: ''})
        } catch (error) {
            setError(true);
            setErrorMessage('Er ging helaas wat fout tijdens het downloaden van het financiele overzicht');
        } 
    };

    useEffect(() => {
        updateTitle('Financieel');
    }, [updateTitle]);

    return (
        <Box m="20px">
            <Typography variant="h2" mb="40px">Financieel overzicht downloaden</Typography>
            {error && (
                <Alert severity="error" mb="40px">{errorMessage}</Alert>
            )}
            <Formik onSubmit={handleOnCreate} initialValues={initialValues} validationSchema={entitySchema} enableReinitialize>
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Field name="startDate">
                                {({ field }) => (
                                    <DatePicker
                                        {...field}
                                        label="Begin datum"
                                        value={values.startDate}
                                        format="DD-MM-YYYY"
                                        onChange={(date) => handleChange({ target: { name: "startDate", value: date } })}
                                        onBlur={handleBlur}
                                        renderInput={(params) => <TextField {...params} helperText={touched.startDate && errors.startDate} error={touched.startDate && Boolean(errors.startDate)} />}
                                    />
                                )}
                            </Field>
                            <Field name="endDate">
                                {({ field }) => (
                                    <DatePicker
                                        {...field}
                                        label="Eind datum"
                                        value={values.endDate}
                                        format="DD-MM-YYYY"
                                        onChange={(date) => handleChange({ target: { name: "endDate", value: date } })}
                                        onBlur={handleBlur}
                                        renderInput={(params) => <TextField {...params} helperText={touched.endDate && errors.endDate} error={touched.endDate && Boolean(errors.endDate)} />}
                                    />
                                )}
                            </Field>
                        </LocalizationProvider>
                        
                    </Box>
                    <Box display="flex" justifyContent="start" mt="20px">
                        <Button type="submit" color="primary" variant="contain">
                            Downloaden
                        </Button>
                        <a href="/" style={{ display: 'none'}} ref={linkRef}>Download</a>
                    </Box>
                </form>
            )}
        </Formik>
        </Box>
    );
}

export default Financial;